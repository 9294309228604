import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import axios from 'axios';
import { ProductDetailModel } from "../../../framework/src/Interfaces/IProductDetail";
import React from "react";
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string | null;
  loading: boolean;
  id: number | null;
  isEdit: boolean;
  product: any;
  activeStep: number;
  isProductDetailScreen: boolean;
  description: string;
  gender: string;
  material: string;
  length: string;
  width: string;
  height: string;
  closureId: string | null;
  pockets: string;
  compartment: string;
  quantity: string;
  hasProductDetailPageError: boolean;
  galleries: any[];
  initialGalleries: any[];
  imageFiles: Blob[];
  pocketData: any[]

  // add color and size screen states
  productData: any[];
  initialproductVariants: any[];
  productArray: any;
  product_attributes: any;
  size: Array<string>;
  hasError: boolean;
  deletedVariantIndex: number;
  hasErrorInDeleteVariant: boolean;
  errorMessageInDeleteVariant: string;
  sizeData: any[],
  colorAndSizePlaceHolder: string;
}

interface SS {

}

export default class ProductDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getProductVariantApiCallId: any;
  deleteProductVariantApiCallId: any
  addProductApiCallId: any
  addProductDetaiApiCallId: any;
  getClosureApiCallId: any;
  getPocketsApiCallId: any;
  closureData = [{ value: '', label: '' }];
  pocketData = [{ value: '', label: '' }];

  // add update size and color
  addUpdateColorAndSizeApiCallId: any;
  getSizeListApiCallId: any;
  // DeleteProductVariantApiCallId: any;
  deleteVariantImageApiCallId: any;
  sizeData = [{ value: '', label: '' }];

  file: Blob | null;
  hasProductDetailPageError: boolean = false;

  myRef: React.RefObject<HTMLDivElement>;
  mycolorAndSizeRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  public productDetailIntialValues: ProductDetailModel = {
    description: "",
    gender: "",
    material: "",
    length: "",
    width: "",
    height: "",
    closureId: "",
    pockets: "",
    compartment: "",
    quantity: "",
  }

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      token: localStorage.getItem('authToken'),
      loading: false,
      id: Number(localStorage.getItem('id')) || null,
      isEdit: Boolean(localStorage.getItem('isEdit')),
      product: {},
      activeStep: 1,
      isProductDetailScreen: false || true,
      description: "",
      gender: "",
      material: "",
      length: "",
      width: "",
      height: "",
      closureId: "",
      pockets: "",
      compartment: "",
      quantity: "",
      hasProductDetailPageError: false,
      galleries: [],
      initialGalleries: [],
      imageFiles: [],
      pocketData: [],
      // add color and size
      productData: [{ attributes: { id: 0, name: '', color: '', sku: '', galleries: [], size: '', measurement_size_value_id: '' } }],
      initialproductVariants: [],
      productArray: [{ id: 1, product_name: '', color: '', sku: '', size: [] }],
      product_attributes: [{ product_name: '', color: '', sku: '', size: [] }],
      size: [],
      hasError: false,
      deletedVariantIndex: 0,
      hasErrorInDeleteVariant: false,
      errorMessageInDeleteVariant: '',
      sizeData: [],
      colorAndSizePlaceHolder: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.file = null;
    this.myRef = React.createRef();
    this.mycolorAndSizeRef = React.createRef();

    this.getClosureList();
    this.getPocketsList();
    // Customizable Area End

  }

  async componentDidMount() {
    //  api
    if (this.state.id) {
      this.getProductData();
    }
  }

  async receive(from: string, message: Message) {
    console.log('api call ------------------>>>')
    console.log('message ------------------>>>', message)
    console.log('message ------------------>>>', message.id)

    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.VendorAddUpdateProductDataMessage)
      );
      if (data) {
        const { id, isEdit } = data;
        this.setState({
          id: id,
          isEdit: isEdit
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log('response ------------------>>>', responseJson);
      console.log('apiRequestCallId ------------------>>>', apiRequestCallId);
      console.log('this.DeleteProductVariantApiCallId ------------------>>>', this.deleteProductVariantApiCallId);

      if (apiRequestCallId && responseJson.data) {
        if (apiRequestCallId === this.getProductVariantApiCallId) {
          this.getProductVariantApiCallId = null;
          // store product data into product state
          this.setState({ product: responseJson.data });
          const { product } = this.state;
          if (product && product.attributes) {
            const data = product.attributes;
            const productData = data.catalogue_variants_attributes && (data.catalogue_variants_attributes as any[]).length ? (data.catalogue_variants_attributes as any[]).sort((a, b) => a.id - b.id) : this.state.productData;
            this.setState({
              description: data.description || "",
              gender: data.gender || "",
              material: data.material_type || "",
              length: data.length || "",
              width: data.width || "",
              height: data.height,
              closureId: data.closure_id && data.closure_id[0] && data.closure_id[0].id || "",
              pockets: data.pockets?.id || "",
              compartment: data.compartment || "",
              quantity: data.quantity || "",
              galleries: data.galleries || "",
              initialGalleries: data.galleries || "",
              productData: productData,
              // initialproductVariants: data.catalogue_variants_attributes
            }, () => {
              this.setState({ initialproductVariants: [...data.catalogue_variants_attributes] })
              this.setColorAndSizePlaceHolder(data.catalogue_variants_attributes, true);
            }
            )
          }
        } else if (apiRequestCallId === this.addProductDetaiApiCallId) {
          this.setState({ loading: false });

          this.addProductDetaiApiCallId = null;
          this.gotoProductPriceDetailScreen();
        } else if (apiRequestCallId === this.getClosureApiCallId) {
          this.getClosureApiCallId = null;
          this.closureData = [];
          responseJson.data.map((d: any) => {
            this.closureData.push({ label: d.attributes.name, value: d.id })
          })
        } else if (apiRequestCallId === this.getPocketsApiCallId) {
          this.pocketData = [];
          responseJson.data.map((d: any) => {
            this.pocketData.push({ label: d.attributes.name, value: d.id })
          });
          this.setState({ pocketData: this.pocketData });

        } else if (apiRequestCallId === this.getSizeListApiCallId) {
          this.getSizeListApiCallId = null;
          this.sizeData = [];

          let sizeList: any = []
          responseJson &&
            responseJson.data &&
            responseJson.data.length > 0 &&
            responseJson.data.map((item: any, index: any) => {
              this.sizeData.push({ value: item.attributes.id, label: item.attributes.label_value });
            });
          this.setState({ sizeData: sizeList });
        } else if (apiRequestCallId === this.addUpdateColorAndSizeApiCallId) {
          this.setState({ loading: false });
          this.addUpdateColorAndSizeApiCallId = null;
          let productData: any[] = responseJson.data.attributes.catalogue_variants_attributes
          productData = productData.sort((a, b) => a.id - b.id)
          this.setState({
            isProductDetailScreen: true,
            productData: productData,
            initialproductVariants: responseJson.data.attributes.catalogue_variants_attributes
          }, () => this.setColorAndSizePlaceHolder(responseJson.data.attributes.catalogue_variants_attributes))
          // this.setState({ isProductDetailScreen: true }, () => this.setColorAndSizePlaceHolder(this.state.productData));
        } else if (apiRequestCallId === this.deleteProductVariantApiCallId) {
          this.deleteProductVariantApiCallId = null;
          console.log("delete Product variant in receive");
          console.log("responseJson::", responseJson);
          if (responseJson?.data && (responseJson.data as any[]).length) {
            if (responseJson.data[0].error) {
              this.setState({ hasErrorInDeleteVariant: true, errorMessageInDeleteVariant: responseJson.data[0].error });
            } else if (responseJson.data[0].success === "true") {
              let productVariant = [...this.state.productData];
              const variant = [...productVariant.slice(0, this.state.deletedVariantIndex), ...productVariant.slice(this.state.deletedVariantIndex + 1)]
              this.setState({ productData: variant, deletedVariantIndex: 0 });
            }
          }

        } else if (apiRequestCallId === this.deleteVariantImageApiCallId) {
          this.deleteVariantImageApiCallId = null;
          console.log("delete image respose:::", responseJson);
        }
      }
    }
    // Customizable Area End
  }

  handleChange = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  }

  gotoProductPriceDetailScreen = () => {
    console.log("next:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationProductPriceDetailMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
      id: this.state.id,
      isEdit: this.state.isEdit
    });
    this.send(msg);
  }

  gotoProductBaiscDetailScreen = () => {
    console.log("back:::");
    localStorage.setItem('isEdit', 'true');
    const msg: Message = new Message(
      getName(MessageEnum.NavigationProductBasicDetailMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
      id: this.state.id,
      isEdit: this.state.isEdit
    });
    this.send(msg);
  }

  setColorAndSizePlaceHolder = (productdata: any[], isFromEdit?: boolean) => {
    let color = '';
    let size = '';
    let placeholder = '';

    if (productdata && productdata.length) {
      if (isFromEdit) {
        productdata.forEach((product: any, index: number) => {
          size = `${size}${product.attributes.measurement_size_value}${(productdata.length - 1) > index ? "," : ''} `;
          color = `${color}${product.attributes.color}${(productdata.length - 1) > index ? "," : ''} `;
        });
        placeholder = `${size}/${color}`
      } else {
        productdata.forEach((product: any, index: number) => {
          size = `${size}${product.attributes.measurement_size_value}${(productdata.length - 1) > index ? "," : ''} `;
          color = `${color}${product.attributes.color}${(productdata.length - 1) > index ? "," : ''} `;
        });
        placeholder = `${size}/${color}`
      }
      this.setState({ colorAndSizePlaceHolder: placeholder });
    } else {
      this.setState({ colorAndSizePlaceHolder: placeholder });
    }
  }

  // Customizable Area Start
  getClosureList() {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClosureApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDropDown + "?value_type=closure"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPocketsList() {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPocketsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDropDown + "?value_type=pockets"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSizeList() {
    console.log("size list api calling");
    const header = {
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSizeListApiCallId = requestMessage.messageId;

    const data = this.state.product.attributes;
    const category_id = data.product_type && (data.product_type as any[]).length > 0 && data.product_type[0] && data.product_type[0].id
    const sub_category_id = data.sub_category_id && (data.sub_category_id as any[]).length > 0 && data.sub_category_id[0] && data.sub_category_id[0].id || ''

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.MeasurementSizeApiEndPoint + `?categories_id=${category_id}&sub_categories_id=${sub_category_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProductData = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const productId = this.state.id;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductVariantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductDataApi + `/${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addProductDetailApi = (values: ProductDetailModel) => {

    let hasError = false;
    if (!this.state.galleries || !this.state.galleries.length || !this.state.productData || !this.state.productData.length) {
      hasError = true;
    }

    if (hasError) {
      this.setState({ hasProductDetailPageError: true });
      this.myRef.current?.scrollIntoView({ behavior: 'auto' });
      return true;
    }

    const { description, gender, closureId, length, width, height, compartment, material, pockets, quantity } = values;

    this.setState({ loading: true });

    const header = {
      // "Content-Type": 'multipart/form-data',
      'Accept': 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addProductDetaiApiCallId = requestMessage.messageId;

    let productID = this.state.id

    const uploadedImageId =
      this.state.initialGalleries &&
      this.state.initialGalleries &&
      this.state.initialGalleries.map((galleries: any) => { return (galleries.id) });

    const filterImages =
      this.state.galleries &&
      this.state.galleries.length &&
      this.state.galleries.filter((gallerie: any) => !uploadedImageId.includes(gallerie.id));

    let formdata = new FormData();

    const images =
      filterImages &&
      filterImages.length &&
      filterImages.map((data: any, index) =>
        formdata.append(`galleries[]`, data.imageBlob as Blob, (data.imageBlob as any).name)
      );

    formdata.append('description', this.state.description);
    formdata.append('gender', this.state.gender);
    formdata.append('material_type', this.state.material);
    formdata.append('length', this.state.length);
    formdata.append('width', this.state.width);
    formdata.append('height', this.state.height);
    formdata.append('closure_id', this.state.closureId as string);
    formdata.append('pockets', this.state.pockets);
    formdata.append('compartment', this.state.compartment);
    formdata.append('quantity', this.state.quantity);
    formdata.append('screen', "2");

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productEditAPiEndPoint + `/${productID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  deleteProductImage = (id: string) => {

    let galleries = this.state.galleries.filter(item => item.id !== id);

    const imagesId =
      this.state.initialGalleries &&
      this.state.initialGalleries &&
      this.state.initialGalleries.map((galleries) => { return (galleries.id) });
    if (imagesId.includes(id)) {
      // if images uploaded on api
      this.setState({ galleries: galleries }, () => this.deleteImage(id));
    } else {
      this.setState({ galleries: galleries });
    }
  }

  deleteImage = (id: string) => {

    const header = {
      // "Content-Type": 'multipart/form-data',
      'Accept': 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteVariantImageApiCallId = requestMessage.messageId;

    let formdata = new FormData();
    formdata.append('galleries[][id]', id);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteImageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadMultipleImages = (e: any) => {
    const images = [...this.state.galleries];

    const previewImages = this.state.galleries;
    const newImages: Blob[] = Array.from(e.target.files);
    const imageLength = this.state.galleries && this.state.galleries.length;
    for (let i = 0; i < newImages.length; i++) {
      images.push({ id: imageLength + i, url: URL.createObjectURL(newImages[i]), imageBlob: newImages[i] });
    }

    const imageUploadData: any[] = [...this.state.imageFiles, Array.from(e.target.files)];
    this.setState({ galleries: images });
  }


  deleteFile = (e: any) => {
    const s: any[] = this.state.galleries.filter((item, index) => index !== e);
    this.setState({ galleries: s });
  }

  displaySizeAndColorScreen = () => {
    this.setState({
      isProductDetailScreen: false,
      hasError: false
    }, () => this.getSizeList());
  }

  handleSizeChange(event: any) {
    let value = Array.from(
      event.target.selectedOptions,
      (option: any) => option.value
    );
    this.setState({ size: value });
  }

  /* Add Colors and Sizes start */

  // add more product
  addProductInputView = () => {
    let attribute: any = {
      attributes: {
        id: this.state.productData.length,
        product_name: '',
        color: '',
        sku: '',
        galleries: [],
        size: '',
        measurement_size_value_id: '',

        image_id: null   // for making default image
      }
    }
    let newData = this.state.productData;
    newData.push(attribute);
    this.setState({ productData: newData });
  }

  // remove product
  onRemoveProductView = (index: any, variantItemId: any) => {
    let isAlreadyPresentIn = false

    this.state.initialproductVariants.forEach((variant: any) => { if (variant.attributes.id == variantItemId) { isAlreadyPresentIn = true } });
    if (isAlreadyPresentIn) {
      this.deleteProductVariantApi(variantItemId);
      this.setState({ deletedVariantIndex: index });
    } else {
      let productVariant = [...this.state.productData];
      const variant = [...productVariant.slice(0, index), ...productVariant.slice(index + 1)]
      this.setState({ productData: variant });
    }
  }

  removeDeleteVariantToastMessage = () => {
    this.setState({ hasErrorInDeleteVariant: false });
  }

  // ्data storing
  addProductVariant = (value: any, item: any, isFromTextField: any, sizeLable?: any) => {
    let productVariants = this.state.productData;
    let selectedIndex = productVariants.findIndex((itemFilter: any) => itemFilter.attributes.id === item.attributes.id);
    if (isFromTextField === 'product_name')
      productVariants[selectedIndex].attributes.product_name = value;
    else if (isFromTextField === 'color') {
      const color = value as string;
      productVariants[selectedIndex].attributes.color = color.trim().charAt(0).toUpperCase() + color.slice(1);
    }
    else if (isFromTextField === 'sku')
      productVariants[selectedIndex].attributes.sku = value;
    else if (isFromTextField === 'size') {
      productVariants[selectedIndex].attributes.measurement_size_value_id = value;
      productVariants[selectedIndex].attributes.size = sizeLable
    }

    this.setState({ productData: productVariants });
  }

  addProductVariantImages = (e: any, item: any) => {
    let productVariants = this.state.productData;
    let selectedIndex = productVariants.findIndex((itemFilter: any) => itemFilter.attributes.id === item.attributes.id);

    const images = [...this.state.productData[selectedIndex].attributes.galleries];

    const previewImages = this.state.productData[selectedIndex].attributes.galleries;
    const newImages: Blob[] = Array.from(e.target.files);
    const imageLength = previewImages && previewImages.length;
    for (let i = 0; i < newImages.length; i++) {
      images.push({ id: imageLength + i, url: URL.createObjectURL(newImages[i]), imageBlob: newImages[i] });
    }

    productVariants[selectedIndex].attributes.galleries = images;
    this.setState({ productData: productVariants });
  }

  deleteProductVariantImage = (index: number, imageObject: any) => {
    let productVariants = this.state.productData;

    let variantGalleries = this.state.productData[index].attributes.galleries.filter((item: any) => item.id !== imageObject.id);
    productVariants[index].attributes.galleries = variantGalleries;

    if (imageObject && imageObject.imageBlob) {
      this.setState({ productData: productVariants });
    } else {
      this.setState({ productData: productVariants }, () => this.deleteImage(imageObject.id));
    }
  }

  makeImageDefault = (index: number, imageObject: any) => {
    let productVariants = this.state.productData;

    let variantImages: any[] = this.state.productData[index].attributes.galleries;

    variantImages =
      variantImages &&
      variantImages.length &&
      variantImages.map((variantImage: any) => {
        if (imageObject.id === variantImage.id) {
          productVariants[index].attributes.image_id = imageObject.id;
          variantImage.is_default = true;
        } else {
          variantImage.is_default = false
        }
        return variantImage;
      }) || [];

    productVariants[index].attributes.galleries = variantImages;
    this.setState({ productData: productVariants });
  }

  /* update product item */

  AddUpdateProductVariantApi = async (event: any) => {
    event.preventDefault();

    let hasError = false;
    if (!this.state.productData || !this.state.productData.length) {
      this.setState({ hasError: true })
      return true;
    }

    if (this.state.productData && this.state.productData.length) {
      if (!this.state.isProductDetailScreen) {
        hasError = this.validateProductVariants();
        if (hasError) {
          this.mycolorAndSizeRef.current?.scrollIntoView({ behavior: "auto" })
          this.setState({ hasError: true })
          return true;
        }
      }
    }

    this.setState({ loading: true })
    const header = {
      // "Content-Type": configJSON.apiContentType,
      'Accept': 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUpdateColorAndSizeApiCallId = requestMessage.messageId;

    console.log("this.state.initialproductVariants:::", this.state.initialproductVariants)
    const initialVariantId = this.state.initialproductVariants.map((variant: any) => { return variant.attributes.id });
    let formdata = new FormData();
    let productData: any[] = [];
    this.state.productData &&
      this.state.productData.length ?
      this.state.productData.map((product: any, index: number) => {
        let p: any = {};
        console.log("initialVariantId:::", initialVariantId)
        console.log("Product attribute id:::", product.attributes.id);
        console.log("boolean:::", initialVariantId.includes(product.attributes.id));

        let isEditVariant = false
        if (initialVariantId.includes(product.attributes.id)) {
          isEditVariant = true;
          formdata.append(`catalogue_variants_attributes[${index}][id]`, product.attributes.id);
        }

        //image upload
        const uploadedImageId =
          this.state.initialproductVariants &&
          this.state.initialproductVariants &&
          this.state.initialproductVariants.map((productVariant: any) => { return (productVariant.attributes.galleries.id) });

        const filterImages =
          product.attributes.galleries &&
          (product.attributes.galleries as any[]).length &&
          product.attributes.galleries.filter((gallerie: any) => !uploadedImageId.includes(gallerie.id));

        const images =
          filterImages &&
          filterImages.length &&
          filterImages.map((data: any) => {
            if (data.imageBlob) {
              formdata.append(`catalogue_variants_attributes[${index}][galleries][]`, data.imageBlob as Blob, (data.imageBlob as any).name)
            }
            return formdata;
          }
          );

        // end 
        product.attributes.image_id && formdata.append(`catalogue_variants_attributes[${index}][image_id]`, product.attributes.image_id || "");
        formdata.append(`catalogue_variants_attributes[${index}][product_name]`, product.attributes.product_name || "");
        formdata.append(`catalogue_variants_attributes[${index}][color]`, (product.attributes.color as string).trim());
        formdata.append(`catalogue_variants_attributes[${index}][sku]`, product.attributes.sku);
        formdata.append(`catalogue_variants_attributes[${index}][size_id]`, product.attributes.measurement_size_value_id);

        productData.push(formdata);
        return;
      }
      ) : [];

    formdata.append('screen', "2");

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productEditAPiEndPoint + `/${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  deleteProductVariantApi = (productVariantId: number) => {
    console.log("Delete Product variant api:::", this.deleteProductVariantApiCallId);
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProductVariantApiCallId = requestMessage.messageId;
    console.log("api call DeleteProductVariantApiCallId:::", this.deleteProductVariantApiCallId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteProductVariantApiEndPoint + `/${productVariantId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // deleteProductVariant = async (variantID: number) => {

  //   const header = {
  //     "Content-Type": configJSON.apiContentType,
  //     token: this.state.token
  //   };

  //   const apiRequest = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.DeleteProductVariantApiCallId = apiRequest.messageId;


  //   apiRequest.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.deleteProductVariantApiEndPoint + `/${variantID}`
  //   );

  //   apiRequest.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   apiRequest.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.deleteApiMethodType);

  //   runEngine.sendMessage(apiRequest.id, apiRequest);
  //   return true;
  // }

  validateProductVariants = () => {
    let hasError = false;
    this.state.productData &&
      this.state.productData.length &&
      this.state.productData.map((variant: any) => {
        if (!variant.attributes.color || !variant.attributes.sku || !variant.attributes.galleries || (variant.attributes.galleries as any[]).length === 0 || !variant.attributes.measurement_size_value_id) {
          hasError = true
        }
      });
    return hasError;
  }
  // Add color and size end
  // Customizable Area End

}

