import React from "react";

import PackagingController, {
  Props,
  configJSON,
} from "./PackagingController.web";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Menu,
  MenuItem,
} from "../../../../node_modules/@szhsin/react-menu";

import {
  withStyles,
} from "@material-ui/core/styles";
const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

// import CloseIcon from '@mui/icons-material/Close';

const styles: any = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "* , *:after , *:before": {
      boxSizing: "border-box",
    },
    "& h2": {
      margin: "0 0 30px",
      textAlign: "center",
      fontFamily: "'Roboto',sans-serif",
      textTransform: "uppercase",
    },
    "& h3": {
      margin: "0 0 30px",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "50px 100px",
    },
    "& .parcel-list": {
      padding: "0",
      margin: "0 0 40px",
    },
    "& .parcel-list .parcel-item": {
      padding: "0 0 20px",
      borderBottom: "1px solid #666",
      marginBottom: "30px",
    },
    "& .parcel-list .parcel-item:last-child": {
      border: "none",
      margin: "0",
    },
    "& .parcel-list .parcel-item h4": {
      margin: "0 0 10px",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .parcel-list .parcel-item p": {
      margin: "0 0 10px 0",
      color: "#666",
      fontFamily: "'Roboto',sans-serif",
      fontSize: "13px",
      lineHeight: "16px",
      textTransform: "uppercase",
    },
    "& .parcel-item .tab": {
      border: "1px solid #000",
      padding: "2px 10px",
      borderRadius: "3px",
      display: "inline-block",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .menu-block": {
      textAlign: "right",
    },
    "& .btn-wrap": {
      textAlign: "center",
      cursor: "pointer"
    },
    "& .black-btn": {
      backgroundColor: "#000",
      color: "#fff",
      width: "250px",
      borderRadius: "0",
      padding: "10px",
      textTransform: "capitalize",
      boxShadow: "none",
    },
  },
  h2: {
    textAlign: "center",
  },
  unitText: {
    fontFamily: "'Roboto',sans-serif",
    position: "absolute",
    top: "5px",
    right: "0",
    fontWeight: "600",
  },
};



export class PackagingWeb extends PackagingController {
  myRef: React.RefObject<HTMLDivElement>;
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    this.myRef = React.createRef()
    // Customizable Area End
  }



  render() {
    const { classes } = this.props;
    return (
      <div ref={this.myRef} className={classes.pageOuter}>
        <div>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Packaging</h2>
              </Grid>
              <Grid item xs={12}>
                <div className="block-wrapper">
                  <h3>Parcel packages</h3>
                  <List
                    component="nav"
                    aria-label="main mailbox folders"
                    className="parcel-list"
                  >

                    {this.state.data.length == 0 ? <h6>packages not added </h6> : <> {Array.from(this.state.data).reverse().map((val: any) => (<ListItem className="parcel-item" id={val.id}>
                      <Grid container>
                        <Grid item xs={9} className="detail-block">
                          <h4>{val.attributes.package_name}</h4>
                          <p>LWH: {val.attributes.package_length} x {val.attributes.package_width} x {val.attributes.package_height} CM</p>
                          {/* {this.state.is_defulat?<> </>:<><span className="tab">Default</span> </>} */}
                          {val.attributes.is_default ? <><span className="tab">Default</span>  </> : <></>}
                        </Grid>
                        <Grid item xs={3} className="menu-block">
                          <Menu
                            menuButton={
                              <div >
                                <IconButton onClick={() => { this.onClick(val.id, val.attributes.package_name, val.attributes.package_length, val.attributes.package_width, val.attributes.package_height); this.setState({ Checked: val.id }); console.log(val.id, this.state.Checked) }}>
                                  <MoreVertIcon />
                                </IconButton>
                              </div>
                            }
                          >
                            <MenuItem

                              onClick={() => {
                                this.props.navigation.navigate('EditPackageBlock')
                              }}



                            >Edit</MenuItem>
                            <MenuItem
                              onClick={() => { this.onDelete() }}
                            >Delete</MenuItem>
                            <MenuItem
                              onClick={() => { this.onMakeDefault() }}
                            >Make Default</MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>
                    </ListItem>))}</>}


                  </List>
                  <div className="btn-wrap">
                    <Button
                      type="button"
                      className="black-btn"
                      onClick={() => {
                        // this.setState({
                        //   showNewPackagingDialog: true,
                        // });
                        this.props.navigation.navigate("AddNewPackage"); this.myRef.current?.scrollIntoView({ behavior: 'auto' })
                      }}
                    >
                      Add New Packaging
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(PackagingWeb);















