import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { spykarBeachYellowShirt } from "../../Wishlist/src/assets";
import { ProductPolicyModel } from "../../../framework/src/Interfaces/IProductDetail";
import { toast } from "react-toastify";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string | null;
  id: number | null;
  isEdit: boolean;
  product: any;
  warrantyPeriodData: any[];
  activeStep: number;
  warranty: string;
  warrantyPeriodId: string | null;
  isReturnable: string;
  displayReturnPolicyField: boolean;
  returnPolicyId: string | null;
  returnPolicyData: any[];
  isExchangeable: string;
  isCODAvailable: string;
  isSaleAvailable: string;
}

interface SS { }

export default class AddProductPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addProductPolicyApi: any;
  warrantyPeriodApi: any;
  returnPolicyApiApiCallId: any;
  getProductDataApiCallId: any;
  warrantyPeriodData = [{ label: "", value: "" }];
  returnPolicyData = [{ label: "", value: "", isActive: "" }];
  // Customizable Area End

  public productOtherInfoIntialValues: ProductPolicyModel = {
    warranty: "",
    warrantyPeriodId: "",
    isReturnable: "",
    returnPolicyId: "",
    isExchangeable: "",
    isCODAvailable: "",
    isSaleAvailable: "",
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      token: localStorage.getItem("authToken"),
      id: Number(localStorage.getItem("id")) || null,
      isEdit: Boolean(localStorage.getItem("isEdit")),
      product: {},
      warrantyPeriodData: [],
      warranty: "",
      warrantyPeriodId: "",
      isReturnable: "",
      displayReturnPolicyField: false,
      returnPolicyId: "",
      returnPolicyData: [],
      isExchangeable: "",
      isCODAvailable: "",
      isSaleAvailable: "",
      activeStep: 4,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    if (this.state.isEdit) {
      this.getProductData();
    } else {
      this.getwarrantyPeriodData();
    }
  }

  async receive(from: string, message: Message) {
    console.log("api call ------------------>>>");
    console.log("message ------------------>>>", message);
    console.log("message ------------------>>>", message.id);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.VendorAddUpdateProductDataMessage)
      );
      if (data) {
        const { id, isEdit } = data;
        this.setState({
          id: id,
          isEdit: isEdit,
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("json response ------------------>>>", responseJson.data);

      if (apiRequestCallId && responseJson.data) {
        this.receiveBlock1(apiRequestCallId, responseJson)
      }

      // api errors
      if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      }

      // api handle catch response
      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  // Customizable Area End

  receiveBlock1 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getProductDataApiCallId) {
      this.getProductDataSuccessResponse(responseJson);
    } else if (apiRequestCallId === this.warrantyPeriodApi) {
      this.getWarrentyPeriodSuccessResponse(responseJson);
    } else if (apiRequestCallId === this.returnPolicyApiApiCallId) {
      this.getReturnPolicySuccessResponse(responseJson)
    } else if (apiRequestCallId === this.addProductPolicyApi) {
      this.addProductPolicySuccessResponse(responseJson)
    }
  }

  getProductDataSuccessResponse = (responseJson: any) => {
    // store product data into product state
    this.setState({ product: responseJson.data });
    const { product } = this.state;
    if (product && product.attributes) {
      const data = product.attributes;
      this.setState(
        {
          // warrantyPeriodId: data.warranty_period_id && data.warranty_period_id[0] && data.warranty_period_id[0].id || "",
          warranty: data.warranty,
          isReturnable: data.is_it_returnable,
          displayReturnPolicyField: data.is_it_returnable,
          isCODAvailable: data.cod_available,
          isExchangeable: data.is_it_exchangeble,
          isSaleAvailable: data.on_sale,
        },
        () => {
          this.getwarrantyPeriodData();
          this.getReturnPolicyData();
        }
      );
    }
  }
  getWarrentyPeriodSuccessResponse = (responseJson: any) => {
    this.warrantyPeriodApi = null;

    this.warrantyPeriodData = [];

    let data: any = "";
    let warrantyPeriodId = "";
    if (Object.keys(this.state.product).length) {
      data = this.state.product.attributes;
      warrantyPeriodId =
        (data.warranty_period_id &&
          data.warranty_period_id[0] &&
          data.warranty_period_id[0].id) ||
        "";
    }

    responseJson.data &&
      (responseJson.data as any[]).length &&
      responseJson.data.map((d: any) => {
        this.warrantyPeriodData.push({
          label: d.attributes.name,
          value: d.id,
        });
      });
    this.setState({
      warrantyPeriodData: this.warrantyPeriodData,
      warrantyPeriodId: warrantyPeriodId,
    });
  }
  getReturnPolicySuccessResponse = (responseJson: any) => {
    this.returnPolicyApiApiCallId = null;

    this.returnPolicyData = [];

    let data: any = "";
    let returnPolicyId = "";
    if (Object.keys(this.state.product).length) {
      data = this.state.product.attributes;
      returnPolicyId = data.return_policy_id;
    }

    responseJson.data &&
      (responseJson.data as any[]).length &&
      responseJson.data.map((d: any) => {
        this.returnPolicyData.push({
          label: d.attributes.days,
          value: d.id,
          isActive: d.attributes.is_active,
        });
      });
    this.setState({
      returnPolicyData: this.returnPolicyData,
      returnPolicyId: returnPolicyId,
    });
  }
  addProductPolicySuccessResponse = (responseJson: any) => {
    // go to next page :::
    localStorage.removeItem("id");
    localStorage.removeItem("isEdit");
    if (responseJson?.data?.attributes) {
      toast.success("Due to change in product detail, Product goes for Admin review", { position: "top-right", autoClose: 5000 })
    }
    this.gotoProductListScreen();
  }

  handleChange = (event: any) => {
    console.log("event.target.name:::", event.target.name);
    if (event.target.name === "isReturnable") {
      this.setState({
        ...this.state,
        [event.target.name]: event.target.value,
        displayReturnPolicyField: event.target.value,
      });
    } else {
      this.setState({
        ...this.state,
        [event.target.name]: event.target.value,
      });
    }
  };
  gotoProductListScreen = () => {
    console.log("next:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationInventoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  gotoProductOtherInfoScreen = () => {
    console.log("back:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationProductOtherInfoMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
      id: this.state.id,
      isEdit: this.state.isEdit,
    });
    this.send(msg);
  };

  getProductData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const productId = this.state.id;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductDataApi + `/${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getwarrantyPeriodData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.warrantyPeriodApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDropDown + "?value_type=warranty_period"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReturnPolicyData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.returnPolicyApiApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReturnPolicy
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addProductPolicyApiCall = (values: ProductPolicyModel) => {
    const {
      isCODAvailable,
      isExchangeable,
      isReturnable,
      returnPolicyId,
      isSaleAvailable,
      warranty,
      warrantyPeriodId,
    } = values;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addProductPolicyApi = requestMessage.messageId;

    let productID = this.state.id;

    const httpBody = {
      warranty: warranty,
      warranty_period_id: warrantyPeriodId,
      is_it_returnable: isReturnable,
      return_policy_id: isReturnable ? returnPolicyId : null,
      is_it_exchangeble: isExchangeable,
      cod_available: isCODAvailable,
      on_sale: isSaleAvailable,
      screen: 5
    };

    console.log("http body ------>>>", httpBody);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productEditAPiEndPoint + `/${productID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
