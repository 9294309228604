import React, { useState } from "react";
import moment from "moment";
import CarouselSlide from "react-multi-carousel";
import ImageGallery from "react-image-gallery";
import { toast } from 'react-toastify';
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import { arrowLeft, arrowRight, blueSuit, champion, starImg, starImgWhite, dummySizeChart } from "./assets"
import "react-image-gallery/styles/css/image-gallery.css"
import "react-toastify/dist/ReactToastify.css";
import ProductDetailController from "./ProductDetailController.web";
import Loader from "../../../components/src/Loader.web";
import { Dialog, DialogContent, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { reviewOffset } from "../../../framework/src/Constants";
import { getAuthToken, roundUpRating } from "../../../framework/src/commonFunction";
import { imageModel, ReviewModal } from "../../../framework/src/Interfaces/IProductDetail";
import { Login_Message } from "../../../framework/src/EnumMessage";
import { toastWarnMessage } from "../../../components/src/toastMessage";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
  pageOuter: {
    "& .MuiAccordion-root": {
      boxShadow: "none",
    },
    "& .MuiAccordionDetails-root .content": {
      margin: "0",
    },

    "& .thumb-slider .carousel .control-prev.control-arrow:before": {
      backgroundImage: `url(${arrowLeft})`,
    },
    "& .thumb-slider .carousel .control-next.control-arrow:before": {
      backgroundImage: `url(${arrowRight})`,
    },
  },
};

const productResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1200, min: 959 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 959, min: 599 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 2,
  },
};

const galleries = [
  {
    original: blueSuit,
    thumbnail: blueSuit,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  }
];
export class ProductDetail extends ProductDetailController {
  constructor(props: any) {
    super(props);
  }

  render() {
    const exchangeItem: any = localStorage.getItem("exchangeItem")
    const exItem = JSON.parse(exchangeItem)

    const { classes } = this.props;

    const { product, productId, sale_price, maximum_retail_price, prouductVariantName } = this.state;

    let category_name = '', category_id = '', sub_category_name = '', sub_category_id = '', sub_sub_category_name = '', sub_sub_category_id = '';

    if (product && product.attributes) {
      category_name = product && product?.attributes?.product_type && product.attributes.product_type.length && product.attributes.product_type[0].name || '';
      category_id = product && product.attributes.product_type && product.attributes.product_type.length && product.attributes.product_type[0].id || '';

      sub_category_name = product && product.attributes.sub_category_id && product.attributes.sub_category_id.length && product.attributes.sub_category_id[0].name || '';
      sub_category_id = product && product.attributes.sub_category_id && product.attributes.sub_category_id.length && product.attributes.sub_category_id[0].id || '';

      sub_sub_category_name = product && product.attributes.sub_sub_category_id && product.attributes.sub_sub_category_id.length && product.attributes.sub_sub_category_id[0].name || '';
      sub_sub_category_id = product && product.attributes.sub_sub_category_id && product.attributes.sub_sub_category_id.length && product.attributes.sub_sub_category_id[0].id || '';
    }

    const reviews: ReviewModal[] = product?.attributes?.review?.data && product?.attributes?.review?.data.length ? product?.attributes?.review?.data : [];

    let sortedReviewsByRating = [...reviews];
    sortedReviewsByRating = sortedReviewsByRating && sortedReviewsByRating.length ? sortedReviewsByRating.sort((a, b) => b.attributes.rating - a.attributes.rating) : [];

    sortedReviewsByRating = sortedReviewsByRating.slice(0, this.state.displayReviewCount);

    const discount = parseFloat((((Number(maximum_retail_price) - Number(sale_price)) * 100) / Number(maximum_retail_price)).toString()).toFixed(2);

    let imageGalleryList: any[] = [];

    this.state.productGalleries &&
      this.state.productGalleries.length &&
      this.state.productGalleries.map((variant: imageModel) => {
        if (variant.id && variant.url) {
          let imageObject = variant;
          let image = Object.assign({}, galleries[0]);

          image.original = imageObject.url;
          image.thumbnail = imageObject.url;
          if (variant.is_default) {
            imageGalleryList.unshift(image);
          } else {
            imageGalleryList = imageGalleryList.concat(image);
          }
        }
      });
    return (
      <>
        {/* product detail page */}
        {this.state.loading ? <Loader loading={this.state.loading} /> : null}
        <div className={classes.pageOuter}>
          <Container maxWidth="lg" className="product-container">
            {product && Object.keys(product).length ?
              <>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link onClick={(e: any) => {
                    e.preventDefault();
                    this.props.navigation.navigate('Home')
                  }}>
                    Home
                  </Link>
                  {category_name && <Link href={`/Itemlist?category_id=${category_id}`}> {category_name} </Link>}
                  {sub_category_name && <Link href={`/Itemlist?sub_category_id=${sub_category_id}`}> {sub_category_name} </Link>}
                  {sub_sub_category_name && <Link href={`/Itemlist?sub_sub_category_id=${sub_sub_category_id}`}> {sub_sub_category_name} </Link>}
                  <Typography>{prouductVariantName}</Typography>
                </Breadcrumbs>
                <div className="product-info-wraper">
                  <div className="product-media">
                    <ImageGallery
                      items={imageGalleryList}
                      thumbnailPosition="left"
                      showPlayButton={false}
                      showFullscreenButton={false}
                      disableSwipe={true}
                      disableThumbnailScroll={false}
                      slideOnThumbnailOver={true}
                    />
                  </div>
                  <div className="product-info-main">
                    <h2 className="product-title">{product.attributes.brand_name || "product brand name display here"}</h2>
                    <div className="product-name">{prouductVariantName}</div>
                    {Number(product.attributes.average_rating) > 0 &&
                      < div className="rating">
                        <div className="rating-no">{roundUpRating(+product.attributes.average_rating)}</div>
                        <span className="rating-star">
                          <img src={starImg} />
                        </span>
                      </div>
                    }
                    <div className="pricing-wrapper">
                      <span className="price">₹{Number(sale_price)}</span>
                      <span className="price old-price">₹{Number(maximum_retail_price)}</span>
                      {isFinite(Number(discount)) ? <span className="offer">({Number(discount)}% off)</span> : null}
                    </div>
                    {this.state.colorList &&
                      this.state.colorList.length ?
                      <div className="product-size-info">
                        <div className="size-heading">
                          <h4 className="size-title">Select Color</h4>
                        </div>
                        <ul className="size-choose">
                          {this.state.colorList.map((color: string) => {
                            return (
                              <>
                                <li onClick={() => this.getVariantByColor(productId, color)} className={this.state.selectedColor === color ? "size-list selected" : "size-list"}><Link>{color}</Link></li>
                              </>
                            )
                          })
                          }
                        </ul>

                        {this.state.hasColorError && !this.state.selectedColor && <span style={{ color: "red" }}>Please Select Colour</span>}
                      </div>
                      :
                      <></>
                    }
                    {this.state.sizeList &&
                      this.state.sizeList.length ?
                      <div className="product-size-info">
                        <div className="size-heading">
                          <h4 className="size-title">Select Size</h4>
                          <a onClick={() => this.setState({ showSizeChart: true })} className="size-guide">Size Guide</a>
                        </div>
                        <ul className="size-choose">
                          {this.state.sizeList.map((size: any) => {
                            return (
                              <>
                                <li onClick={(e) => this.getVarintBySizeId(productId, size.id)} className={this.state.selectedSizeId === size.id ? "size-list selected" : "size-list"}><Link>{size.size_name}</Link></li>
                              </>
                            )
                          })
                          }
                        </ul>
                        {this.state.hasSizeError && !this.state.selectedSizeId && <span style={{ color: "red" }}>Please Select Size</span>}
                      </div>
                      :
                      <></>
                    }
                    <div className="product-actions">
                      {this.state.isWishlisted ?
                        <Button
                          href=""
                          type="submit"
                          variant="contained"
                          className="outline-btn"
                          style={{ cursor: "default" }}
                        // onClick={(e) => { this.removeFromWishist(productId) }}
                        >
                          WISHLISTED
                        </Button> :
                        <Button
                          href=""
                          type="submit"
                          variant="contained"
                          className="outline-btn"
                          onClick={(e) => {
                            if (!getAuthToken()) {
                              toastWarnMessage(Login_Message.TokenRequire);
                            }
                            if (localStorage.getItem("groupId")) {
                              this.addToGroupWishlist(productId);
                            }
                            this.addToWishistSingle(productId)
                          }}
                        >
                          wishlist
                        </Button>
                      }
                      {this.state.isVariantAlreadyAddedToCart ?
                        <Button
                          href=""
                          type="submit"
                          variant="contained"
                          className="black-btn addbag-btn"
                          onClick={(e) => { this.props.navigation.navigate("ShoppingBag") }}
                        >
                          Go to bag
                        </Button> :
                        <Button
                          href=""
                          type="submit"
                          variant="contained"
                          className="black-btn addbag-btn"
                          onClick={(e) => {
                            if (!getAuthToken()) {
                              toastWarnMessage(Login_Message.TokenRequire);
                            } else {

                              console.log(this.state.cartId, this.state.cartList.length, "id and length")
                              console.log(this.state.exchangeFlow, this.state.cartItemCount, "flow and count")
                              console.log(this.state.exchangeFlow, this.state.cartLength, "lengthhhhh")
                              if (this.state.exchangeFlow &&
                                // this.state.cartLength == 1
                                this.state.cartItemCount == 1
                              ) {

                                this.handleOpenMessagePopup()

                              } else {
                                this.addToCart(productId)

                              }


                            }
                          }
                          }
                        // }
                        >
                          Add to bag
                        </Button>
                      }
                    </div>
                    <div className="product-description">
                      <h4 className="desc-title">Description</h4>
                      <p className="desc-content">{product.attributes.description}</p>
                      <h4 className="desc-title">Product Details</h4>
                      <ul className="desc-detail">
                        <li>
                          <div className="title">Material</div>
                          <div className="value">{product.attributes.material_type}</div>
                        </li>
                        {product.attributes.occasion_id && product.attributes.occasion_id.length ?
                          <li>
                            <div className="title">Occasion</div>
                            <div className="value">{product.attributes.occasion_id && product.attributes.occasion_id.length ? product.attributes.occasion_id[0].name : ""}</div>
                          </li> :
                          null
                        }
                        {product.attributes.bullet_point_1 ?
                          <li><div className="value">{product.attributes.bullet_point_1}</div></li> : null
                        }
                        {product.attributes.bullet_point_2 ?
                          <li><div className="value">{product.attributes.bullet_point_2}</div></li> : null
                        }
                        {product.attributes.bullet_point_3 ?
                          <li><div className="value">{product.attributes.bullet_point_3}</div></li> : null
                        }
                        {product.attributes.bullet_point_4 ?
                          <li><div className="value">{product.attributes.bullet_point_4}</div></li> : null
                        }
                        {product.attributes.bullet_point_5 ?
                          <li><div className="value">{product.attributes.bullet_point_5}</div></li> : null
                        }
                        {/* <li>
                      <div className="title">Pattern</div>                      <div className="value">Printed</div>
                    </li> */}
                      </ul>
                      {/* <h4 className="desc-title">Size &amp; Fit</h4>
                      <p className="desc-content">The model (height 5'8") is wearing a size S</p> */}
                      {
                        product.attributes.care_instruction &&
                        <>
                          <h4 className="desc-title">Care Instruction</h4>
                          <p className="desc-content">{product.attributes.care_instruction}</p>
                        </>
                      }
                      {product.attributes.is_it_returnable ?
                        <>
                          <h4 className="desc-title">Return Policy</h4>
                          <p className="desc-content">Valid till {product?.attributes.return_policy?.days || 10} days after delivered your items</p>
                          <span dangerouslySetInnerHTML={{ __html: product?.attributes.return_policy?.description }} />
                        </>
                        :
                        null}
                      <h4 className="desc-title">Country of Origin</h4>
                      <p className="desc-content">{product.attributes.country_of_origin}</p>
                    </div>
                    <div className="delivery-detail">
                      <h4 className="desc-title">Delivery</h4>
                      <p className="desc-content">Ships within X Days. Takes 3-5 days for delivery</p>
                    </div>

                    {/* need to implement in next feature */}
                    {sortedReviewsByRating && sortedReviewsByRating.length &&
                      <>
                        <div className="ratings-wrapper">
                          <h4 className="desc-title">Rating &amp; Reviews</h4>
                          <div className="rating">
                            <div className="rating-no">{roundUpRating(+product.attributes.average_rating)}</div>
                            <span className="rating-star">
                              <img src={starImg} />
                            </span>
                          </div >
                          <div className="desc-count">{reviews.length} Verified Buyers</div>
                        </div >
                        <div className="product-reviews">
                          <h4 className="desc-title">Rating &amp; Reviews</h4>
                          <div className="reviews-detailed">
                            {sortedReviewsByRating && sortedReviewsByRating.map((review) => {
                              const reviewDate = moment(review.attributes.created_at).format('DD MMM YYYY');
                              return (
                                <div className="review-wrapper">
                                  <div className="rating">
                                    <div className="rating-no">{review.attributes.rating}</div>
                                    <span className="rating-star">
                                      <img src={starImgWhite} />
                                    </span>
                                  </div>
                                  <div className="review-content">
                                    {review.attributes.comment &&
                                      <div className="product-desc">{review.attributes.comment}</div> || null
                                    }
                                    <div className="thumb-container">
                                      {review.attributes.images && review.attributes.images.length &&
                                        review.attributes.images.map((image) => {
                                          return (
                                            <div className="thumb-image">
                                              <img src={image.url} alt="product-img" />
                                            </div>
                                          )
                                        })
                                        || null
                                      }
                                    </div>
                                    <div className="reviewer">
                                      <span>{review.attributes.username}</span>
                                      <span className="review-date">{reviewDate}</span>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        {
                          (reviews && reviews.length >= reviewOffset) && (reviews && reviews.length > this.state.displayReviewCount) ?
                            <a target="_blank" href={`/ProductReview/${productId}`} className="view-link">View All {reviews.length} reviews</a> : null}
                        {/* // <button onClick={(e) => this.setState({ displayReviewCount: this.state.displayReviewCount + reviewOffset })} className="view-link">Load More</button> : null} */}
                        {/* <a href={`/ProductReview/${productId}`} className="view-link">View All 248 reviews</a> */}
                      </>
                      || null
                    }
                  </div >
                </div >
              </>
              :
              <>
                <br /> <br /> <br />
                <div style={{ textAlign: "center" }}> No product found </div>
                <br /> <br /> <br />
              </>
            }
            <div className="product-carousel">
              {this.state.similarProductList &&
                this.state.similarProductList.length &&
                <>
                  <h3 className="product-heading">Similar Products</h3>
                  <CarouselSlide
                    renderDotsOutside
                    responsive={productResponsive}
                    showDots={false}
                    className="product-items"
                  >
                    {this.state.similarProductList.map((item) => {
                      const product = item.attributes;
                      const sale_price = product && (product.sale_price ? product.sale_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.sale_price : "");
                      const maximum_retail_price = product && product.maximum_retail_price && (product.maximum_retail_price ? product.maximum_retail_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.maximum_retail_price : "");
                      const discount = parseFloat(((((maximum_retail_price - sale_price) * 100) / maximum_retail_price) as unknown) as string).toFixed(2);

                      const imageUrl = item.attributes.galleries && (item.attributes.galleries as any[]).length && item.attributes.galleries[0].url || null
                      return (
                        <>
                          <Grid
                            // key={item.img}
                            className="product-item"
                          >
                            <div className="product-item-info" onClick={() => {
                              window.open(`${window.location.protocol}//${window.location.host}/ProductDetail/${item.id}`);
                            }}>
                              <div className="product-item-photo">
                                <span className="product-image-wrapper">
                                  <img
                                    id="viewAllImg"
                                    src={imageUrl ? `${imageUrl}` : undefined}
                                  />
                                </span>
                              </div>
                              <div className="product-item-details">

                                <div className="product-name">
                                  <div className="product-brand">{item.attributes.brand_name}</div>
                                  <a href="#" className="product-link">
                                    {item.attributes.name}
                                  </a>
                                </div>
                                <div className="pricing-wrapper">
                                  <span className="price">₹{sale_price}</span>
                                  <span className="price old-price">₹{maximum_retail_price}</span>
                                  {isFinite(Number(discount)) && <span className="offer">({discount}% off)</span>}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                  </CarouselSlide>
                </>
                || null
              }
            </div>

            <div className="product-carousel">
              {this.state.sameBrandProductList &&
                this.state.sameBrandProductList.length &&
                <>
                  <h3 className="product-heading">More From XBrand</h3>
                  <CarouselSlide
                    renderDotsOutside
                    responsive={productResponsive}
                    showDots={false}
                    className="product-items"
                  >
                    {this.state.sameBrandProductList.map((item) => {
                      const product = item.attributes;
                      const sale_price = product && (product.sale_price ? product.sale_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.sale_price : "");
                      const maximum_retail_price = product && product.maximum_retail_price && (product.maximum_retail_price ? product.maximum_retail_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.maximum_retail_price : "");
                      const discount = parseFloat(((((maximum_retail_price - sale_price) * 100) / maximum_retail_price) as unknown) as string).toFixed(2);

                      const imageUrl = item && item.attributes && item.attributes.galleries && (item.attributes.galleries as any[]).length && item.attributes.galleries[0].url || null
                      return (
                        <>
                          <Grid
                            // key={item.img}
                            className="product-item"
                          >
                            <div className="product-item-info" onClick={() => {
                              window.open(`${window.location.protocol}//${window.location.host}/ProductDetail/${item.id}`);
                            }}>
                              <div className="product-item-photo">
                                <span className="product-image-wrapper">
                                  <img
                                    id="viewAllImg"
                                    src={imageUrl ? `${imageUrl}` : undefined}
                                  />
                                </span>
                              </div>
                              <div className="product-item-details">

                                <div className="product-name">
                                  <div className="product-brand">{item.attributes.brand_name}</div>
                                  <a href="#" className="product-link">
                                    {item.attributes.name}
                                  </a>
                                </div>
                                <div className="pricing-wrapper">
                                  <span className="price">₹{sale_price}</span>
                                  <span className="price old-price">₹{maximum_retail_price}</span>
                                  {isFinite(Number(discount)) && <span className="offer">({discount}% off)</span>}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                  </CarouselSlide>
                </>
                || null
              }
            </div>

            <div className="product-carousel">
              {this.state.interestedInProductList &&
                this.state.interestedInProductList.length &&
                <>
                  <h3 className="product-heading">You might be interested in</h3>
                  <CarouselSlide
                    renderDotsOutside
                    responsive={productResponsive}
                    showDots={false}
                    className="product-items"
                  >
                    {this.state.interestedInProductList.map((item) => {
                      const product = item.attributes;
                      const sale_price = product && (product.sale_price ? product.sale_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.sale_price : "");
                      const maximum_retail_price = product && product.maximum_retail_price && (product.maximum_retail_price ? product.maximum_retail_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.maximum_retail_price : "");
                      const discount = parseFloat(((((maximum_retail_price - sale_price) * 100) / maximum_retail_price) as unknown) as string).toFixed(2);

                      const imageUrl = item.attributes.galleries && (item.attributes.galleries as any[]).length && item.attributes.galleries[0].url || null
                      return (
                        <>
                          <Grid
                            // key={item.img}
                            className="product-item"
                          >
                            <div className="product-item-info" onClick={() => {
                              window.open(`${window.location.protocol}//${window.location.host}/ProductDetail/${item.id}`);
                            }}>
                              <div className="product-item-photo">
                                <span className="product-image-wrapper">
                                  <img
                                    id="viewAllImg"
                                    src={imageUrl ? `${imageUrl}` : undefined}
                                  />
                                </span>
                              </div>
                              <div className="product-item-details">

                                <div className="product-name">
                                  <div className="product-brand">{item.attributes.brand_name}</div>
                                  <a href="#" className="product-link">
                                    {item.attributes.name}
                                  </a>
                                </div>
                                <div className="pricing-wrapper">
                                  <span className="price">₹{sale_price}</span>
                                  <span className="price old-price">₹{maximum_retail_price}</span>
                                  {isFinite(Number(discount)) && <span className="offer">({discount}% off)</span>}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                  </CarouselSlide>
                </>
                || null
              }
            </div>

            <div className="product-carousel">
              {this.state.recentlyViewProductList &&
                this.state.recentlyViewProductList.length &&
                <>
                  <h3 className="product-heading">Recently View Products</h3>
                  <CarouselSlide
                    renderDotsOutside
                    responsive={productResponsive}
                    showDots={false}
                    className="product-items"
                  >
                    {this.state.recentlyViewProductList.map((item) => {
                      const product = item.attributes;
                      const sale_price = product.sale_price;
                      const maximum_retail_price = product.maximum_retail_price;
                      const discount = parseFloat(((((maximum_retail_price - sale_price) * 100) / maximum_retail_price) as unknown) as string).toFixed(2);

                      const imageUrl = item.attributes.galleries && (item.attributes.galleries as any[]).length && item.attributes.galleries[0].url || null
                      return (
                        <>
                          <Grid
                            // key={item.img}
                            className="product-item"
                          >
                            <div className="product-item-info" onClick={() => {
                              window.open(`${window.location.protocol}//${window.location.host}/ProductDetail/${item.attributes.catalogue_id}`);
                            }}>
                              <div className="product-item-photo">
                                <span className="product-image-wrapper">
                                  <img
                                    id="viewAllImg"
                                    src={imageUrl ? `${imageUrl}` : undefined}
                                  />
                                </span>
                              </div>
                              <div className="product-item-details">

                                <div className="product-name">
                                  <div className="product-brand">{item.attributes.brand_name}</div>
                                  <a href="#" className="product-link" title={item.attributes.name}>
                                    {item.attributes.name}
                                  </a>
                                </div>
                                <div className="pricing-wrapper">
                                  <span className="price">₹{sale_price}</span>
                                  <span className="price old-price">₹{maximum_retail_price}</span>
                                  {isFinite(Number(discount)) && <span className="offer">({discount}% off)</span>}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                  </CarouselSlide>
                </>
                || null
              }
            </div>

            <Dialog
              fullWidth={true}
              maxWidth={'md'}
              aria-labelledby="customized-dialog-duplicate"
              open={this.state.showSizeChart}
              className="dummy-chart-dialog"
            >
              <DialogContent>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="h5">
                      Size Chart
                    </Typography>
                    {/* <Typography variant="subtitle1">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography> */}
                  </Box>
                  <Box>
                    <CloseIcon
                      cursor="pointer"
                      onClick={(e) => {
                        this.setState({
                          showSizeChart: false,
                        });
                      }}
                    />
                  </Box>
                </Box>
                <img width="100%" src={dummySizeChart}></img>
              </DialogContent>
            </Dialog>

          </Container >



          {/* message popup  */}
          {/* <Dialog
            open={this.state.messagePopup}
            onClose={this.handleCloseMessagePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Message"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                you cannot add another product while exchange flow is in process
              </DialogContentText>
            </DialogContent>
            <DialogActions>
         
              <Button onClick={() => this.handleCloseMessagePopup()} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog> */}


          {/* message popup changed design  */}

          <Dialog
            open={this.state.messagePopup}
            aria-labelledby="simple-dialog-title"
            fullWidth={true} maxWidth={"xs"}
          >
            <Box className="myBox" >
              <Box textAlign="right" style={{
                paddingRight: "11px",
                paddingTop: "6px"
              }}>
                <span className="close-btn" style={{ cursor: "pointer" }}>
                  <CloseIcon
                    onClick={() => this.handleCloseMessagePopup()}
                  ></CloseIcon>
                </span>
              </Box>
              <Box className="modal-head" style={{ padding: "17px" }} fontWeight="600" p={1}>
                <Typography style={{ textAlign: "justify" }} variant="h5"> You cannot add another product while exchange flow is in process<b>

                </b>


                </Typography>
              </Box>
              <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">

                <Button className="black-btn"
                  onClick={(e) => {
                    this.handleCloseMessagePopup()

                  }

                  }
                  size="medium"
                >
                  OK
                </Button>
              </Box>
            </Box>
          </Dialog>



        </div >
      </>
    );
  }
}

export default withStyles(styles)(ProductDetail);