import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../../../web/public/dialog.css"
const emptyCart = require("../assets/emptyCart.png")
import { toast } from 'react-toastify';
import { Typography } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import Alert from '@material-ui/lab/Alert';
import ShoppingController, {
  //  Props
  configJSON
} from "../../ShoppingCart/src/ShoppingController.web";
const loyalty = require("../assets/loyalty.png");
const coupon = require("../assets/coupon.png");

import {
  Box,
  InputLabel,
  Input,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web"
import Sweetalert from "react-bootstrap-sweetalert";

interface Props {
  classes: any;
}
interface State { }

// web Order Details 1 start
const styles = {
  shoppingOuter: {
    padding: "50px 0",
    background: "#f6f6f6",
    "& .MuiGrid-grid-xs-12": {
      padding: "0 10px",
    },
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "uppercase",
      color: "#121212",
    },
    "& .total-items": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "500",
      color: "#121212",
    },
    "& .productlist-wrapper": {
      padding: "0",
    },
    "& .productlist-wrapper .product-list .product-img": {
      minHeight: "80px",
      height: "auto",
      "@media (max-width:767px)": {
        height: "60px",
      },
    },
    "& .product-heading a": {
      color: "#212121",
      opacity: "0.6",
      textDecoration: "none",
    },
    "& .pricing-link a": {
      color: "#212121",
      opacity: "0.6",
      textDecoration: "none",
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: "500",
      textTransform: "uppercase",
    },
    "& .product-list": {
      border: "0",
      cursor: "pointer"
    },
    "& .product-desc.size-qty a": {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#121212",
      fontWeight: "600",
      background: "#f1f1f1",
      padding: "4px 13px",
      borderRadius: "4px",
      textDecoration: "none",
      display: "inline-block",
    },
    "& .product-desc.size-qty .selected-size:after": {
      content: '',

    },
    "& .product-desc.size-qty a:not(:last-child)": {
      margin: "0 15px 0 0",
    },
    "& .shop-rightblog-wrapper": {
      "@media (max-width:960px)": {
        margin: "30px 0 0",
      },
    },
    "& .shop-rightblog-wrapper .shop-right-list:not(:last-child)": {
      margin: "0 0 20px",
    },
    "& .shop-right-list": {
      background: "#fff",
      padding: "20px",
    },
    "& .shop-right-list h4": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 17px",
    },
    "& .loyalty-point": {
      display: "flex",
      alignItems: "center",
      margin: "0 0 15px",
    },
    "& .loyalty-point .loyalty-img": {
      display: "inline-block",
      maxWidth: "22px",
      margin: "0 12px 0 0",
    },
    "& .loyalty-point p": {
      fontSize: "14px",
      lineHeight: "16px",
      margin: "0",
      color: "#5d5d66",
    },
    "& .loyalty-point p span": {
      fontWeight: "600",
      color: "#121212",
    },
    "& .loyalty-info p": {
      margin: "0",
      fontSize: "12px",
      lineHeight: "14px",
      color: "#0057ff",
      // margin: "0 0 15px",
    },
    "& .loyalty-point-list .form-group:last-child": {
      margin: "0 0 0",
    },
    "& .loyalty-point-list .form-group .info-msg": {
      marginTop: "30px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ff4949",
    },
    "& .loyalty-point-list .form-group .MuiFormControlLabel-root": {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#212121",
    },
    "& .apply-coupon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .apply-coupon .apply-list": {
      display: "flex",
      alignItems: "center",
    },
    "& .apply-coupon .apply-list p": {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#121212",
      margin: "0",
    },
    "& .apply-coupon .apply-list .apply-img": {
      maxWidth: "20px",
      display: "inline-block",
      marginRight: "16px",
      position: "relative",
      top: "2px",
    },
    "& .apply-coupon .apply-btn svg": {
      width: "18px",
      height: "18px",
      color: "#212121",
    },
    "& .product-order-wrapper p": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#212121",
      fontWeight: "400",
      margin: " 0 0 16px",
      display: "flex",
      justifyContent: "space-between",
      "@media (max-width:575px)": {
        fontSize: "13px",
      },
    },
    "& .product-order-wrapper p a": {
      color: "#21c998",
      textDecoration: "none",
    },
    "& .product-order-wrapper p span": {
      display: "inline-block",
      minWidth: "131px",
      color: "#b6b6b6",
      fontWeight: "400",
    },
    "& .product-order-list p:last-child": {
      margin: "0",
    },
    "& .product-order-list .total": {
      padding: "15px 0 0",
      borderTop: "1px solid #e3e3e3",
    },
    "& .product-order-list .total p": {
      fontWeight: "600",
    },
    "& .product-order-list .total p span": {
      fontWeight: "600",
      color: "#212121",
    },
    "& .shop-right-list .black-btn": {
      margin: "25px 0 0",
      width: "100%",
    },
  },
};


const error_msg: any =

{

  marginTop: "15px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#ff4949",
}

const discountFont: any = { fontSize: "20px" }

const numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
// Web Order Details 1 End
export class Shopping extends ShoppingController {


  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // placeCustomerOrder = (): void => {
  //   if (this.state.isUserVerified) {
  //     this.props.navigation.navigate("AddressWeb")
  //   } else {
  //     toast.error(User_Message.UserNotVerified, { autoClose: 7000 })
  //   }
  // }

  // placeCustomerExchangeOrder = () => {
  //   if (this.state.isUserVerified) {
  //     this.props.navigation.navigate("AddressWeb")
  //   } else {
  //     toast.error(User_Message.UserNotVerified, { autoClose: 7000 })
  //   }
  // }

  renderToastMessage = () => {
    return (
      <>
        {this.state.exchangeFlow ? this.state.alert ? <Alert severity="info" color="warning">You can order <b>Only 1</b> replacement item for this exchange <b> Please select only one item. </b></Alert> : <></> : <></>}

        {this.state.isRemoved ? toast.success('Removed from Bag', { position: "top-right", autoClose: 1500, onOpen: this.RemovedMessage }) : null}
        {this.state.isMoveToWishlist ? toast.success('Moved To Wishlist', { position: "top-right", autoClose: 1500, onOpen: this.MoveToWishlistessage }) : null}
        {this.state.ExchangeFlowDiscarded ? toast.success('Exchange Flow Discarded', { position: "top-right", autoClose: 2500, onOpen: this.ExchangeFlowDiscarded }) : null}
      </>
    )
  }

  renderDiscardExchangeItemButton = () => {
    return (
      <>
        {this.state.exchangeFlow ? <Button style={{ marginBottom: "24px" }}
          onClick={() => {
            localStorage.removeItem("exchangeFlow"); localStorage.setItem("exchangeFlowFalse", "false"); this.setState({ exchangeFlow: false })
            this.RemoveShoppingBagOnExchangeDiscard()
          }}
          type="button"
          variant="contained"
          className="black-btn"
          data-testid="discard-exchange-flow"
        >
          Discard exchange flow
        </Button> : <></>}
      </>
    )
  }

  renderRemoveItemFromBagDialogue = () => {
    return (
      <>
        {/* remove box  */}
        <Dialog
          open={this.state.isDelete}
          aria-labelledby="simple-dialog-title"
          fullWidth={true} maxWidth={"xs"}
          data-testid="remove-item-dialogue"

        >
          <Box className="myBox">
            <Box textAlign="right" p={2}>
              <span className="close-btn">
                <CloseIcon
                  onClick={() => this.handleClose()}
                ></CloseIcon>
              </span>
            </Box>
            <Box className="modal-head" textAlign="center" fontWeight="600" p={1}>
              <Typography variant="h5"> Are you sure, you want to delete? <b>

              </b>

              </Typography>
            </Box>
            <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">
              <Button className="black-btn"
                data-testid="no-remove-from-bag"
                onClick={() => this.handleClose()}
                size="medium"
              >
                No
              </Button>
              <Button className="black-btn"
                data-testid="yes-remove-from-bag"
                onClick={(e) => {
                  e.stopPropagation()
                  if (this.Removeappliedcoupon() &&
                    this.removeLoyaltyPoints()) {
                    this.RemoveShoppingBag()
                  }

                  this.setState({ isRemoved: true });
                  this.handleClose()
                }}
                size="medium"
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    )
  }

  renderApplyCouponDialogue = () => {
    return (
      <>
        {/* apply coupon */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.applyCoupon}
          className="apply-coupon-modal"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Coupons</span>
            <CloseIcon
              id="close-coupon-dialog"
              className="close-dialog"
              onClick={(e) => {
                this.setState({ applyCoupon: false });
                this.setState({ couponCode: "" })
              }}
            />

          </DialogTitle>
          {this.state.invalidCoupon ? <Alert severity="error">{this.state.invalidAlertMessage}</Alert> : ""}
          <DialogContent style={{ width: "520px" }}>
            <div className="form-wrapper">
              <div className="form-group">
                <InputLabel htmlFor="my-input">Enter Coupon Code</InputLabel>
                <FormControl>
                  <Input
                    value={this.state.couponCode}
                    onChange={(e: any) => this.setState({ couponCode: e.target.value })}
                    type="text"
                    id="my-input"
                    aria-describedby="my-helper-text"
                  />
                </FormControl>
              </div>

              <Button
                data-testid="apply-coupon-button1"
                onClick={() => {
                  // this.applyCouponInput()
                  this.appliedcouponInput()
                }}
                type="button"
                variant="contained"
                className="black-btn"
              >
                Apply
              </Button>
            </div>
            <div className="coupon-list-wrapper">
              {this.state.coupons ? <>{this.state.coupons.map((val: any, index: any) => {

                const discount = val.attributes?.amount
                return (
                  <>

                    <div className="coupon-list">
                      <div className="discount">
                        <span style={discountFont}>{discount}{val.attributes?.coupon_type === "percentage_discount" ? "" : "₹"} OFF

                        </span>
                      </div>
                      <div className="discount-details">

                        <p> {val.attributes?.discription.toUpperCase()}</p>
                        <p className="code">
                          Code: <span>{val.attributes?.coupon_code}</span>
                        </p>
                        <div className="code-apply">
                          {val.attributes?.id == this.state.activeCartId?.attributes?.coupon_code_id && this.state.activeCartId?.attributes?.is_coupon_applied ?
                            <p style={{ color: "green" }}>Applied!</p>
                            : <Button
                              onClick={() => {
                                // this.applyCoupon(val.attributes?.coupon_code)
                                this.appliedcoupon(val.attributes?.coupon_code)
                              }}
                              type="button"
                              variant="contained"
                              className="outline-btn"
                              data-testid="coupon-apply-button"
                            >
                              Apply
                            </Button>}
                          <Link id={val.attributes?.coupon_code}
                            onClick={() => { this.showHideToggle(val.attributes?.id, val.attributes?.coupon_code) }}>view details</Link>
                        </div>
                      </div>
                      <div style={{ display: "none" }} id={val.attributes?.id} className="apply-coupun-details">
                        <ul>
                          {val.attributes?.terms_and_condition.map((val: any) => <li><span>{val}</span></li>)
                          }

                        </ul>
                      </div>



                    </div>
                  </>)
              })}

              </> : <><p>No coupons available</p></>}

            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  }

  renderProductSizeUpdateDialogue = () => {
    return (
      <>
        {/* Product Size */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.productSize}
          className="product-dialog"
          data-testid="size-dialogue"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Select Size</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({ productSize: false });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            <ul
              data-testid="list-of-size"
              className="product-item-size">
              {this.state.sizeArray.map((data: any) => <>

                <li style={{ maxWidth: "min-content" }}>
                  <Link
                    id={this.state.size}
                    style={this.state.size == data.catalogue_variant_id ? { border: "1px solid black", } : undefined}
                    onClick={() => { this.setState({ size: data.catalogue_variant_id }) }} >{data?.label_value}</Link>
                </li>
              </>)}
            </ul>
            <Button
              data-testid="size-done-button"
              onClick={() => {
                this.removeLoyaltyPoints()
                this.Removeappliedcoupon()
                if (this.Removeappliedcoupon() &&
                  this.removeLoyaltyPoints()) {

                  this.sizeUpdate(); this.setState({ productSize: false });
                }
              }}
              type="button"
              variant="contained"
              className="black-btn"
            >
              Done
            </Button>
          </DialogContent>
        </Dialog>
      </>
    )
  }

  renderProductQuantityUpdateDialogue = (val: any) => {
    return (
      <>
        {/* Product QTY  */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.productQty}
          className="product-dialog"
          data-testid="quantity-dialogue"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Select Quantity</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({ productQty: false });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            <ul data-testid="list-of-quantity"
              className="product-item-size">
              {numberArray.map((quan) => <>
                <li >
                  <Link
                    id={this.state.quantity}
                    style={this.state.quantity == quan ? { border: "1px solid black" } : undefined}
                    onClick={() => {

                      this.setState({ quantity: quan }
                        // , () => {
                        //  console.log(this.state.quantity) }
                      )

                    }}>{quan}</Link>
                </li>     </>)}

            </ul>

            <Button
              data-testid="quantity-done-button"
              key={val.id}
              onClick={() => {

                this.removeLoyaltyPoints()
                this.Removeappliedcoupon()
                if (this.Removeappliedcoupon() &&
                  this.removeLoyaltyPoints()) {

                  this.quantityUpdate(); this.setState({ productQty: false });
                }

              }}

              variant="contained"
              className="black-btn"
            >
              Done
            </Button>
          </DialogContent>
        </Dialog>

      </>
    )
  }

  sizeAndQuantityUpdateButton = (val: any) => {
    return (
      <>
        <Link
          data-testid="size-quantity-button"
          onClick={(e: any) => {
            e.stopPropagation()
            this.setState({ sizeArray: val.attributes.other_variants }); this.setState({ productSizeId: val.attributes.id }); setTimeout(() => {
              //  console.log(this.state.sizeArray, "size array", this.state.productSizeId)
            })
            this.setState({
              productSize: true,
            });
          }}
        >
          Size: <span className="selected-size">{val.attributes.measurement_size_value}</span>

        </Link>
        <Link
          onClick={(e: any): any => {
            e.stopPropagation()
            this.setState({ productQuantityId: val.id });
            //  console.log(this.state.productQuantityId)
            {
              this.state.exchangeFlow ? this.setState({
                productQty: false, alert: true
              }) : this.setState({
                productQty: true,
              })
            }
          }}
        >
          Qty: <span>{val.attributes.quantity}</span>
        </Link>
      </>
    )
  }

  renderExchangeOrPlaceOrderButton = () => {
    return (
      <>
        {this.state.exchangeFlow ? 
        <Button
          onClick={() => { this.PlaceOrderApi() }}
          type="button"
          variant="contained"
          className="black-btn"
          data-testid="exchange-place-order"
        >
          {this.state.priceDetailsData?.attributes?.total == "0.0" ? "Place Exchange Order" : "Continue"}
        </Button>
          :
          <Button
            onClick={() => { this.PlaceOrderApi() }}
            type="button"
            variant="contained"
            className="black-btn"
            data-testid="place-order"
          >
            {this.state.priceDetailsData?.attributes?.total ? "Place Order" : "Continue"}
          </Button>}
      </>
    )
  }

  renderNameAndPriceOfProduct = (val: any) => {
    return (
      <>
        {val.attributes.product_name}{val.attributes.color ? ` (${val.attributes.color})` : ""}
      </>
    )
  }

  renederTotalPriceOfShoppingCart = () => {
    let amount;
    if (this.state.ShoppingCartData || this.state.ShoppingCartData == undefined) {
      if (this.state.priceDetailsData?.attributes?.total < 0) {
        amount = this.state.priceDetailsData?.attributes?.total * -1;
      } else {
        amount = this.state.priceDetailsData?.attributes?.total;
      }
    } else {
      amount = 0;
    }
    return (
      <>
        {this.state.exchangeFlow ?
          <div className="total">
            <p>
              <span>{this.state.priceDetailsData?.attributes?.total < 0 ? 'Refund' : 'Total'} Amount</span> ₹{amount}
            </p>
            {this.state.priceDetailsData?.attributes?.total < 0 ?
              <p>Refund amount will be credited in Loyalty/Bank Account</p>
              :
              null
            }
          </div>


          :
          <div className="total">
            <p >
              <span>Total Amount</span> ₹
              {this.state.ShoppingCartData || this.state.ShoppingCartData == undefined ? this.state.priceDetailsData?.attributes?.total : 0}
            </p>
          </div>
        }
      </>
    )
  }

  renderExchangePriceAndCouponAndLoyaltyDiscount = () => {
    return (
      <>
        {this.state.exchangeFlow ? <p>
          <span>Exchange Product Price</span> -₹{this.state.ShoppingCartData || this.state.ShoppingCartData == undefined ? this.state.priceDetailsData?.attributes?.exchangeble_item_price
            : 0}
        </p> : <>
          <p >
            <span>Coupon Discount</span>
            {this.state.activeCartId && this.state.activeCartId.attributes?.is_coupon_applied ?
              <>
                -₹{this.state.ShoppingCartData || this.state.ShoppingCartData == undefined ? this.state.priceDetailsData?.attributes?.coupon_discount_amount : 0
                } </> :
              <Link
                id="apply-coupon-priceDetails"
                onClick={() => {
                  this.setState({
                    applyCoupon: true,
                  });
                }}
              >
                Apply Coupon
              </Link>}
          </p>
          <p>
            <span>Loyalty Points</span> -₹{this.state.ShoppingCartData || this.state.ShoppingCartData == undefined ? this.state.priceDetailsData?.attributes?.loyalty_point_discount : 0}
          </p>     </>}
      </>
    )
  }
  renderPriceDetailSection = () => {
    return (
      <>
        <div className="product-order-list">
          <p>
            <span>Total MRP</span> ₹{this.state.ShoppingCartData || this.state.ShoppingCartData == undefined ? this.state.priceDetailsData?.attributes?.sub_total : 0}
          </p>
          <p>
            <span>Discount on MRP</span> -₹{this.state.ShoppingCartData || this.state.ShoppingCartData == undefined ? this.state.priceDetailsData?.attributes?.discount_on_mrp : 0}
          </p>

          {this.renderExchangePriceAndCouponAndLoyaltyDiscount()}
          {/* <p>
                            <span>GST</span> ₹{this.state.ShoppingCartData || this.state.ShoppingCartData == undefined ? this.state.priceDetailsData?.attributes?.total_tax : 0}
                          </p> */}
          <p>
            <span>Delivery Fee</span> ₹
            {this.state.ShoppingCartData || this.state.ShoppingCartData == undefined ? this.state.priceDetailsData?.attributes?.delivery_charges : 0}
          </p>

          {this.renederTotalPriceOfShoppingCart()}
        </div>
      </>
    )
  }

  renderAddAndRemoveCoupon = () => {
    return (
      <>
        {this.state.activeCartId && this.state.activeCartId.attributes?.is_coupon_applied ?
          <div className="add-coupon">
            <h4>Coupons</h4>
            <div onClick={(e) => {
              this.setState({
                applyCoupon: true,
              })
            }} className="apply-coupon">
              <div className="apply-list">
                <span className="apply-img">
                  <img src={coupon} />
                </span>
                <p

                  className="code">{this.state.activeCartId && this.state.activeCartId?.attributes?.coupon_code}</p>
              </div>
              <div className="apply-btn">

                <Link onClick={(e: any) => { e.stopPropagation(); this.Removeappliedcoupon() }}>
                  <CloseIcon />
                </Link>
              </div>
            </div>
          </div>
          :
          <div className="add-coupon">
            <h4>Coupons</h4>
            <div onClick={(e) => {
              this.setState({
                applyCoupon: true,
              })
            }} className="apply-coupon">
              <div className="apply-list">
                <span className="apply-img">
                  <img src={coupon} alt="" />
                </span>
                <p
                  className="code">Apply Coupons</p>
              </div>
              <div className="apply-btn">
                <Link>
                  <ArrowForwardIosIcon />
                </Link>

              </div>
            </div>
          </div>

        }
      </>
    )
  }
  orderPlaceDialogue = (isOrderPlaced: boolean) => {
    return(
      <>
      {isOrderPlaced ? (
        <Sweetalert
          title="ORDER PLACED!"
          success
          confirmBtnText="Continue Shopping"
          confirmBtnStyle={{
            backgroundColor: "black",
            padding: "10px 15%",
          }}
          onConfirm={() => this.goToHomePage()}
          allowEscape={false}
        />
      ) : null}
      </>
    )
  }

  paymentFailedDialogue = (isPaymentFailed: boolean, razorPayErrorResponse: any) => {
    return(
      <>
        {isPaymentFailed ? (
          <Sweetalert
            title="PAYMENT FAILED!"
            error
            confirmBtnText="okay"
            confirmBtnStyle={{
              backgroundColor: "black",
              padding: "10px 15%",
            }}
            onConfirm={() => { this.showRazorPayModel() }}
            allowEscape={false}
          >
            <h6>{razorPayErrorResponse.code}</h6>
            <h6>Error : {razorPayErrorResponse.description}</h6>
            <h6>Order ID : {razorPayErrorResponse.metadata.order_id}</h6>
            <h6>Payment ID : {razorPayErrorResponse.metadata.payment_id}</h6>
          </Sweetalert>
        ) : null}
      </>
    )
  }

  render() {
    const { classes } = this.props;
    const {isOrderPlaced, isPaymentFailed, errorResponse } = this.state;
    return (
      <>
        {/* web Order Details 1 start */}
        <div className={classes.shoppingOuter}>
          <Loader loading={this.state.loading} />
          <Loader loading={this.state.sizeQuantityLoader} />
          <Grid item xs={12}>
            <h2>Shopping Bag</h2>

          </Grid>

          {this.state.ShoppingCartData ? <>
            <Container maxWidth="md">
              {this.renderToastMessage()}
              <Grid container>
                <Grid item xs={12}>

                  <p className="total-items">{this.state.ShoppingCartData.length} Items</p>
                  {this.renderDiscardExchangeItemButton()}

                </Grid>

                <Grid item xs={12} md={7}>
                  <div className="productlist-wrapper">
                    {this.state.ShoppingCartData.length == 0 ? <h6>Empty cart</h6> : <>
                      {this.state.ShoppingCartData.sort(function (a: any, b: any) { return a.id - b.id }).map((val: any) => (<>
                        <Grid
                          onClick={() => {
                            this.props.navigation.navigate("ProductDetail", { productId: val.attributes.catalogue_id })
                          }}
                          className="product-list">
                          <div className="product-list-inner">
                            <div className="product-img">
                              <img className="img-block" src={val.attributes?.galleries?.[0].url} />
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                              <h4 className="product-name">{this.renderNameAndPriceOfProduct(val)}</h4>
                                <Link

                                  onClick={(e: any) => {
                                    this.setState({ isDelete: true })
                                    e.stopPropagation()
                                    this.setState({ ShoppingRemoveBag: val.id })
                                  }}

                                >
                                  <CloseIcon />
                                </Link>
                              </div>
                              <div className="product-desc">
                                {val.attributes.brand_name}
                              </div>
                              <div className="product-desc size-qty">
                                {this.sizeAndQuantityUpdateButton(val)}
                              </div>
                              <div className="pricing-link">
                                <div className="pricing-wrapper">
                                  <span className="price">₹{val.attributes.sale_price}</span>
                                  <span className="price old-price">₹{val.attributes.total_price}</span>
                                  <span className="offer">({parseFloat((((Number(val.attributes.total_price) - Number(val.attributes.sale_price)) * 100) / Number(val.attributes.total_price)).toString()).toFixed(2)}% off)</span>

                                </div>
                                <Link
                                  data-testid="move-to-wishlist"
                                  onClick={(e: any) => {
                                    e.stopPropagation()
                                    this.StatusBlog(val.attributes.is_wishlist, val.attributes.catalogue_id); this.setState({ ShoppingRemoveBag: val.id });

                                  }}>Move to Wishlist</Link>
                              </div>
                            </div>
                          </div>
                        </Grid>

                        {/* Product Size */}
                        {this.renderProductSizeUpdateDialogue()}

                        {/* Product QTY  */}
                        {this.renderProductQuantityUpdateDialogue(val)}


                      </>)
                      )
                      }
                    </>
                    }


                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className="shop-rightblog-wrapper">
                    <div className="shop-right-list" style={{ display: this.state.exchangeFlow ? "none" : "" }}>
                      <div className="loyalty-point-list">
                        <div className="user-detail-inner" >
                          <h4>Loyalty Points</h4>
                          <div className="loyalty-point">
                            <span className="loyalty-img">
                              <img src={loyalty} alt="" />
                            </span>
                            <p>
                              Available Points: <span>{this.state.loyaltyPoints?.attributes?.actual_points}</span>
                            </p>
                          </div>
                          <div className="loyalty-info">
                            <p>₹{this.state.loyaltyPoints?.attributes?.values_in_rs} will be deducted from total MRP</p>
                          </div>
                        </div>
                        <div className="form-group">
                          <RadioGroup aria-label="points" name="points">
                            <FormControlLabel

                              value="Use All Available Points"
                              control={<Radio id="Use All Available Points"
                                onChange={(e: any) => this.setState({
                                  loyaltyDisplay: "none", loyaltyCheck: e?.target?.id,
                                  redeemLoyalty: "use_all_point"
                                })}
                              />}
                              checked={this.state.loyaltyCheck == "Use All Available Points"}
                              label="Use All Available Points"
                            />
                            <FormControlLabel

                              value="Enter Points"
                              control={<Radio id="Enter Points"
                                onChange={(e: any) => this.setState({
                                  loyaltyDisplay: "", loyaltyCheck: e?.target?.id,

                                  redeemLoyalty: "choose_custom_point"
                                })} />}
                              label="Enter Points"
                              checked={this.state.loyaltyCheck == "Enter Points"}
                            />
                          </RadioGroup>
                        </div>

                        <div className="form-group" style={{ display: this.state.loyaltyDisplay }} >
                          <InputLabel htmlFor="points">
                            Enter Loyalty Points
                          </InputLabel>
                          {/* useLoyaltyPoints */}
                          <FormControl>
                            <Input
                              value={this.state.useLoyaltyPoints}
                              type="text"
                              id="points"
                              aria-describedby="my-helper-text"
                              onChange={(e: any) =>
                                this.setState({
                                  useLoyaltyPoints: e?.target?.value
                                })}
                            />
                          </FormControl>
                        </div>
                        <p style={error_msg} >
                          {
                            this.state.priceDetailsData?.attributes?.loyalty_point_discount != 0 ?

                              ` ₹${this.state.priceDetailsData?.attributes?.loyalty_point_discount} will be deducted from total MRP ` :
                              this.state.loyaltyPointsError
                          }
                        </p>
                        <Button
                          data-testid="loaylty-redeem"
                          onClick={() => {
                            this.onPressLoyaltyPointsApply()
                          }}
                          type="button"
                          variant="contained"
                          className="outline-btn"

                          style={{ marginRight: "10px" }}
                          disabled={this.state.priceDetailsData?.attributes?.loyalty_point_discount != 0 || this.state.loyaltyPoints?.attributes?.actual_points == 0}
                        >
                          Redeem
                        </Button>
                        <Button
                          data-testid="remove-loyalty-points"
                          
                          onClick={() => {
                            this.removeLoyaltyPoints()
                          }}
                          type="button"
                          variant="contained"
                          className="outline-btn"
                          disabled={this.state.priceDetailsData?.attributes?.loyalty_point_discount == 0 && (this.state.loyaltyPoints?.attributes?.actual_points == 0 || this.state.loyaltyPoints?.attributes?.actual_points !== 0)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                    <div className="shop-right-list" style={{ display: this.state.exchangeFlow ? "none" : "" }}>
                      {this.renderAddAndRemoveCoupon()}
                    </div>
                    <div className="shop-right-list">
                      <div className="product-order-wrapper">
                        <h4>Price Details</h4>
                        {this.renderPriceDetailSection()}

                        {this.renderExchangeOrPlaceOrderButton()}

                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>


          </> : <>
            <div style={{ height: "500px", textAlign: "center", paddingTop: "150px", margin: "2px 40px" }}>
              <img width="150px" height="150px" src={emptyCart} />
              <h6 >Shopping cart is empty</h6>
              <Button
                data-testid="add-to-wishlist-empty-shoppingBag"
                style={{ border: "1px solid black" }}
                onClick={() => this.props.navigation.navigate("Wishlist")}
              >
                ADD FROM WISHLIST
              </Button>
            </div>
          </>
          }
        </div>
        {/* web Order Details 1 End */}

        {this.renderApplyCouponDialogue()}

        {/* Payment   */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.payment}
          className="payment-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Credit/Debit Card</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({ payment: false });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "460px" }}>
            <div className="payment-wrapper">
              <div className="form-group">
                <InputLabel htmlFor="cardnumber">Card Number</InputLabel>
                <FormControl>
                  <Input
                    type="text"
                    id="cardnumber"
                    aria-describedby="my-helper-text"
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <InputLabel htmlFor="cardname">Name on Card</InputLabel>
                <FormControl>
                  <Input
                    type="text"
                    id="cardname"
                    aria-describedby="my-helper-text"
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <InputLabel htmlFor="valid">Valid Thru (MM/YY)</InputLabel>
                <FormControl>
                  <Input
                    type="text"
                    id="valid"
                    aria-describedby="my-helper-text"
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <InputLabel htmlFor="cvv">CVV</InputLabel>
                <FormControl>
                  <Input
                    type="text"
                    id="cvv"
                    aria-describedby="my-helper-text"
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <FormControlLabel
                  control={<Checkbox
                  // color="default " 
                  />}
                  label="Save this card for future payments"
                />
              </div>
              <Button
                href=""
                type="button"
                variant="contained"
                className="black-btn"
              >
                Make Payment
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {this.renderRemoveItemFromBagDialogue()}

        {/* Order Place modal start */}
        {this.orderPlaceDialogue(isOrderPlaced)}
        {/* Order Place modal end */}
        {this.paymentFailedDialogue(isPaymentFailed, errorResponse)}
      </>
    );
  }
}
export default withStyles(styles)(Shopping);
