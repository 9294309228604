import React, { useState, Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
// import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
// import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";
import VendorProfileController, { Props, configJSON } from "./VendorProfileController.web";
import Loader from "../../../components/src/Loader.web"

// My Profile Start
const styles: any = {
  userouter: {
    // padding: "50px 0",
    "& h2": {
      fontSize: "18px",
      lineHeight: "22px",
      color: "#121212",
      fontWeight: "700",
      borderBottom: "1px solid #e6e6e6",
      margin: "0",
      textTransform: "uppercase",
      padding: "0 0 20px",
    },
    "& .user-detail-wrapper": {
      padding: "30px 200px",
      background: "#f3f3f3",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-inner": {
      background: "#fff",
      margin: "0 0 20px",
      padding: "29px 20px",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-inner h4": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 20px",
    },
    "& .user-personal-detail .user-detail-list:not(:last-child)": {
      margin: "0 0 20px",
    },
    "& .user-personal-detail .user-detail-list p": {
      fontSize: "16px",
      LineHeight: "20px",
      color: "#121212",
      fontWeight: "400",
      margin: "0",
    },
    "& .user-personal-detail .user-detail-list p span": {
      opacity: "0.6",
      minWidth: "175px",
      display: "inline-block",
      "@media (max-width:767px)": {
        display: "block",
        margin: "0 0 3px",
      },
    },
    "& .user-address-wrapper .user-address-list:not(:last-child)": {
      borderBottom: "1px solid #e3e3e3",
      padding: "0 0 25px",
      margin: "0 0 20px",
    },
    "& .user-address-list h6": {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 15px",
    },
    "& .user-address-list p": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#5d5d66",
      fontWeight: "400",
      margin: "0 0 15px",
      maxWidth: "500px",
      textTransform: "capitalize",
      "@media (max-width:767px)": {
        maxWidth: "100%",
      },
    },
    "& .user-address-list a": {
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "500",
      color: "#121212",
      textTransform: "uppercase",
      border: "1px solid #212121",
      borderRadius: "4px",
      padding: "4px 7px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .user-address-list a:hover": {
      background: "#212121",
      color: "#fff",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      padding: "15px 0 0",
      "@media (max-width:767px)": {
        padding: "0 0 0",
      },
    },
    "& .cross_signature": {
      textAlign: "center",
      padding: "15px 0 0",
      "@media (max-width:767px)": {
        padding: "0 0 0",
      },
    }

  },
};
// My Profile End
const signature_img: any =
{
  width: "100%",
  height: "40px",
  borderRadius: "5px",
  // backgroundColor: "#fafafa",
  border: "1px solid rgba(18,18,18,0.15)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflowX: "hidden",
  marginTop: '10px',
  color: "#666"
}

const profileList = [
  { id: "1", category: "My Profile" },
  { id: "2", category: "My Orders" },
  { id: "3", category: "Shopping With Friends" },
  { id: "4", category: "Address Book" },
  { id: "5", category: "Coupons" },
  { id: "6", category: "Policies" },
  { id: "7", category: "Settings" },
  { id: "8", category: "Loyalty Points" }
]
export class VendorProfile extends VendorProfileController {

  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
  }



  render() {
    const { classes } = this.props;

    return (
      <>

        <div className={classes.userouter} >
          <Loader loading={this.state.getProfileLoader} />
          <Grid
          //   item xs={12} md={9}
          >
            <div className="user-detail-wrapper">
              <div className="btn-wrapper">
                <h4 ref={this.myRef}>My Profile </h4>



              </div>
              {/* profile details  */}
              <div className="user-detail-inner" >
                <h4>Personal Details</h4>
                <div className="user-personal-detail">
                  <div className="user-detail-list">
                    <p>
                      <span>Name</span>{this.state.profileDetail.attributes?.name}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>EMail ID</span>{this.state.profileDetail.attributes?.email}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>Mobile Number</span>{this.state.profileDetail.attributes?.phone_number}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>Brand</span>

                      {this.state.displayBrandNames}
                    </p>
                  </div>

                </div>
              </div>
              {/* bank detail  */}
              <div className="user-detail-inner">
                <h4>Bank Details</h4>
                <div className="user-personal-detail">
                  <div className="user-detail-list">
                    <p>
                      <span>Account Holder Name   </span>       {this.state.profileDetail.attributes?.bank_details?.account_holder_name}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>Account Number</span>       {this.state.profileDetail.attributes?.bank_details?.account_number}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>Bank Name</span>       {this.state.profileDetail.attributes?.bank_details?.bank_name}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>IFSC Code</span>       {this.state.profileDetail.attributes?.bank_details?.ifsc_code}
                    </p>
                  </div>

                </div>
              </div>
              <div className="user-detail-inner">
                <h4>Address</h4>
                <div className="user-address-wrapper">
                  {this.state.addressData.length == 0 ? <><h6>No Address</h6></> : this.state.addressData.map((address: any) => <div className="user-address-list">
                    <h6>{address.attributes.name}</h6>
                    <p>
                      {address.attributes.address}, {address.attributes.city}, {address.attributes.state} - {address.attributes.zip_code}
                    </p>
                    <Link href="#">{address.attributes.save_address_as}</Link>
                  </div>

                  )}
                </div>
              </div>
              {/* bank detail  */}
              <div className="user-detail-inner">
                <h4>Business Details</h4>
                <div className="user-personal-detail">
                  <div className="user-detail-list">
                    <p>
                      <span>Business Name</span>{this.state.profileDetail.attributes?.business_details?.data.attributes?.registered_business_name}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>GSTIN</span>{this.state.profileDetail.attributes?.business_details?.data.attributes?.gstin}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>TAN</span>{this.state.profileDetail.attributes?.business_details?.data.attributes?.tan}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p>
                      <span>PAN</span>   {this.state.profileDetail.attributes?.business_details?.data.attributes?.pan}
                    </p>
                  </div>
                  <div className="user-detail-list">
                    <p style={{ display: "flex" }}>
                      <span>Signature</span>
                      <div style={{ width: "121px" }}>
                        {this.state.profileDetail.attributes?.business_details?.data.attributes?.signature_image.url == null || "" ? "" :
                          <img style={signature_img} src={this.state.profileDetail.attributes?.business_details?.data.attributes?.signature_image.url} />
                        }
                      </div>
                    </p>
                  </div>

                </div>
              </div>
              <div className="btn-wrapper">
                <Button
                  onClick={() => { this.props.navigation.navigate("VendorEditProfile") }}
                  variant="contained"
                  className="black-btn"
                  type="button"
                >
                  Edit Profile
                </Button>
              </div>
            </div>
          </Grid>
          {/* </Grid>
          </Container> */}

        </div>




      </>
    );
  }
}

export default withStyles(styles)(VendorProfile);
