import React, { Component } from "react";

import DeliveryAddressController, { Props, configJSON } from "./DeliveryAddressController.web";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { FormControl, InputLabel, Input } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/src/Loader.web"
import Sweetalert from "react-bootstrap-sweetalert";

import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";


const styles: any = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: '50px 0',
    "& h2": {
      margin: "0 0 30px",
      textAlign: "center",
      fontFamily: "'Roboto',sans-serif",
      textTransform: "uppercase",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "20px",
    },
    "& .price-detail-wrap": {
      padding: "15px",
    },
    "& .price-detail-wrap h3": {
      margin: "0 0 20px 0",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .price-detail-list": {
      padding: "0",
      marginBottom: "20px",
    },
    "& .price-detail-list li": {
      padding: "0 0 12px",
      fontFamily: "'Roboto',sans-serif",
      fontSize: "14px",
    },
    "& .price-detail-list li:last-child": {
      borderTop: "1px solid #aaaaaa",
      color: "#000",
      fontWeight: "600",
      padding: "10px 0 0",
    },
    "& .price-detail-list li .title": {
      fontFamily: "'Roboto',sans-serif",
      color: "#aaa",
    },
    "& .price-detail-list li .value": {
      textAlign: "right",
      fontFamily: "'Roboto',sans-serif",
      color: "#aaaaaa",
    },
    "& .price-detail-list li:last-child .title": {
      color: "#000",
    },
    "& .price-detail-list li:last-child .value": {
      color: "#000",
    },
    "& .black-btn": {
      backgroundColor: "#000",
      color: "#fff",
      width: "100%",
      borderRadius: "0",
      padding: "10px",
      textTransform: "capitalize",
      boxShadow: "none",
    },
    "& .radio-btn": {
      borderBottom: "1px solid #cfcfcf",
      padding: "10px 0",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .radio-btn:last-child": {
      border: "none",
    },
    "& .radio-btn .MuiFormControlLabel-root": {
      alignItems: "flex-start",
    },
    "& .radio-btn h3": {
      margin: "10px 0 5px 0",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .radio-btn p": {
      margin: "0 0 10px 0",
      color: "#666",
      fontFamily: "'Roboto',sans-serif",
      fontSize: "13px",
      lineHeight: "16px",
    },
    "& .heading-detail": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "30px",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .border-btn": {
      border: "2px solid #000",
      borderRadius: "0",
      textTransform: "capitalize",
      fontWeight: "bold"


    },
    "& .radio-btn .tab": {
      border: "1px solid #000",
      padding: "2px 10px",
      borderRadius: "3px",
    },
  },
  formGroup: {
    marginBottom: "15px",
  },
  ".MuiFormControl-root": {
    width: "100%",
  },

};
const error_msg = {
  color: "red"
}

export class DeliveryAddress extends DeliveryAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.pageOuter}
      >
        <Loader loading={this.state.placeOrderLoader} />
        <div>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Delivery Address</h2>
              </Grid>
              <Grid item xs={7}>
                <div className="block-wrapper">
                  <div className="heading-detail">
                    <span>Select Delivery Address</span>
                    <Button
                      type="submit"
                      variant="outlined"
                      className="border-btn"
                      onClick={(e) => {
                        this.setState({
                          showNewAddressDialog: true,
                        });
                      }}
                    >
                      Add New Address
                    </Button>
                  </div>
                  <RadioGroup aria-label="select" name="address">
                    {/* {this.state.addressData.reverse().slice(0,3).map((address:any)=>  */}
                    {this.state.addressData.map((address: any) =>
                      <>
                        <div className="radio-btn">
                          <FormControlLabel
                            checked={this.state.deliveryAddressId == address.id}
                            // value="female"

                            onChange={() => { this.setState({ deliveryAddressId: address.id }, () => localStorage.setItem("addressId", address.id)); }}
                            value={address.id}
                            color="#000"
                            control={<Radio />}
                            label={
                              <Typography variant="caption">
                                <h3>{address.attributes.name}</h3>
                                <p>
                                  {address.attributes.address}

                                </p>
                                <p>MO:{address.attributes.phone_number}</p>
                                <span className="tab">{address.attributes.save_address_as}</span>
                              </Typography>
                            }
                          />
                        </div>
                      </>
                    )


                    }
                  </RadioGroup>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="price-detail-wrap block-wrapper">
                  <h3>Price Details</h3>
                  <List
                    component="nav"
                    aria-label="main mailbox folders"
                    className="price-detail-list"
                  >
                    <ListItem>
                      <Grid container>
                        <Grid item xs={8} className="title">
                          Total MRP
                        </Grid>
                        <Grid item xs={4} className="value">
                          ₹{this.state.priceDetailsData.attributes?.sub_total}
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem>
                      <Grid container>
                        <Grid item xs={8} className="title">
                          Discount on MRP
                        </Grid>
                        <Grid item xs={4} className="value">
                          -₹{this.state.priceDetailsData.attributes?.discount_on_mrp}
                        </Grid>
                      </Grid>
                    </ListItem>
                    {this.state.exchangeFlow == true ?

                      <ListItem>
                        <Grid container>
                          <Grid item xs={8} className="title">
                            Exchange Product Price
                          </Grid>
                          <Grid item xs={4} className="value">
                            -₹{this.state.priceDetailsData?.attributes?.exchangeble_item_price
                              || 0}
                          </Grid>
                        </Grid>
                      </ListItem>


                      : <>
                        <ListItem>
                          <Grid container>
                            <Grid item xs={8} className="title">
                              Loyalty Discount
                            </Grid>
                            <Grid item xs={4} className="value">
                              -₹{this.state.priceDetailsData?.attributes?.loyalty_point_discount || 0}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <ListItem>
                          <Grid container>
                            <Grid item xs={8} className="title">
                              Coupon Discount
                            </Grid>
                            <Grid item xs={4} className="value">
                              -₹{this.state.priceDetailsData?.attributes?.coupon_discount_amount || 0}
                            </Grid>
                          </Grid>
                        </ListItem> </>}
                    <ListItem>
                      <Grid container>
                        <Grid item xs={8} className="title">
                          GST
                        </Grid>
                        <Grid item xs={4} className="value">
                          +₹{this.state.priceDetailsData.attributes?.total_tax}
                        </Grid>
                      </Grid>
                    </ListItem>


                    <ListItem>
                      <Grid container>
                        <Grid item xs={8} className="title">
                          Delivery Fee
                        </Grid>
                        <Grid item xs={4} className="value">
                          +₹{this.state.priceDetailsData?.attributes?.delivery_charges || 0}
                        </Grid>
                      </Grid>
                    </ListItem>

                    {this.state.exchangeFlow == true ?
                      <>
                        <ListItem>
                          <Grid container>
                            <Grid item xs={8} className="title">
                              {this.state.priceDetailsData?.attributes?.total < 0 ? 'Refund' : 'Total'} Amount
                            </Grid>
                            <Grid item xs={4} className="value">
                              ₹ {this.state.priceDetailsData?.attributes?.total < 0 ? this.state.priceDetailsData?.attributes?.total * -1 : this.state.priceDetailsData?.attributes?.total}
                              {/* {this.state.priceDetailsData.attributes?.total} */}
                            </Grid>
                            <Grid className="title" >
                              {this.state.priceDetailsData?.attributes?.total < 0 ?
                                <p style={{ marginTop: "15px" }}>Refund amount will be credited in your Loyalty/Bank Account</p>
                                :
                                null
                              }
                            </Grid>
                          </Grid>


                        </ListItem>

                      </>
                      :
                      <ListItem>
                        <Grid container>
                          <Grid item xs={8} className="title">
                            Total Amount
                          </Grid>
                          <Grid item xs={4} className="value">
                            ₹{this.state.priceDetailsData.attributes?.total}
                          </Grid>
                        </Grid>
                      </ListItem>}
                  </List>



                  {/* exchange flow changes start  */}
                  {this.state.exchangeFlow ? <Button
                    onClick={() => {
                      // this.placeExchangeorder(); 
                      // this.props.navigation.navigate("payment")
                      this.placeThisOrder()
                    }}
                    type="submit"
                    disabled={!this.state.deliveryAddressId}
                    className="order-btn black-btn"
                    variant="contained"
                  >
                    {
                      //  this.state.priceDetailsData.attributes?.total == "0.0" 
                      this.state.priceDetailsData.attributes?.total == "0.0" || this.state.priceDetailsData.attributes?.total < 0
                        // parseInt(this.state.priceDetailsData.attributes?.total) == 0
                        ? "Place Exchange Order" : "Continue"}

                  </Button> :


                    <Button
                      onClick={() => { this.placeThisOrder() }}
                      // PlaceOrderApi 
                      type="submit"
                      disabled={!this.state.deliveryAddressId}
                      className="order-btn black-btn"
                      variant="contained"
                    >
                      {
                        // this.state.priceDetailsData.attributes?.total == "0.0" 
                        this.state.priceDetailsData.attributes?.total == "0.0" || this.state.priceDetailsData.attributes?.total < 0
                          // parseInt(this.state.priceDetailsData.attributes?.total) == 0
                          ? "Place Order" : "Continue"}
                    </Button>




                  }
                  {/* exchange flow changes  end  */}

                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        {/* Dupilcate Dialog */}
        <Dialog
          // onClose={this.handleCloseNewAddressDialog}
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.showNewAddressDialog}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ marginRight: "15px" }}
          >
            Add New Address
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseNewAddressDialog} />
            </span>
          </DialogTitle>
          <DialogContent style={{ width: "450px" }}>
            <div className="form-group">
              <InputLabel htmlFor="my-input">
                Name<sup>*</sup>
              </InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }); console.log(this.state.name) }} />
              </FormControl>
              <p style={error_msg}>
                {this.state.nameError}
              </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">Mobile Number<sup>*</sup></InputLabel>
              <FormControl>
                <Input type="number" id="my-input" aria-describedby="my-helper-text" value={this.state.mobile} onChange={(e) => { this.setState({ mobile: e.target.value }); console.log(this.state.mobile) }} />
              </FormControl>
              <p style={error_msg}>
                {this.state.mobileError}
              </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">Pin Code<sup>*</sup></InputLabel>
              <FormControl>
                <Input id="my-input" type="number" aria-describedby="my-helper-text" value={this.state.pincode} onChange={(e) => { this.setState({ pincode: e.target.value }); console.log(this.state.pincode) }} />
              </FormControl>
              <p style={error_msg}>
                {this.state.pincodeError}
              </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">
                Address(House No, Building, street)<sup>*</sup>
              </InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text" value={this.state.address} onChange={(e) => { this.setState({ address: e.target.value }); console.log(this.state.address) }} />
              </FormControl>
              <p style={error_msg}>
                {this.state.addressError}
              </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">Locality / Town<sup>*</sup></InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text" value={this.state.town} onChange={(e) => { this.setState({ town: e.target.value }); console.log(this.state.town) }} />
              </FormControl>
              <p style={error_msg}>
                {this.state.townError}
              </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">City / District<sup>*</sup></InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text" value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }); console.log(this.state.city) }} />
              </FormControl>
              <p style={error_msg}>
                {this.state.cityError}
              </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">State<sup>*</sup></InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text" value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }); console.log(this.state.state) }} />
              </FormControl>
              <p style={error_msg}>
                {this.state.stateError}
              </p>
            </div>

            <div className="form-group">
              <RadioGroup className="align-row" aria-label="address" name="address">
                <h4>Save Address As</h4>
                <FormControlLabel value="home" checked={this.state.save_add === 'home'} control={<Radio />} label="home" onChange={(e: any) => { this.setState({ save_add: e.target.value }, () => console.log(this.state.save_add)) }} />
                <FormControlLabel value="office" control={<Radio />} checked={this.state.save_add === 'office'} label="office" onChange={(e: any) => { this.setState({ save_add: e.target.value }, () => console.log(this.state.save_add)) }} />
              </RadioGroup>
              <p style={error_msg}>
                {this.state.save_addError}
              </p>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            <Button
              type="submit"
              variant="contained"
              style={{ width: "100%" }}
              className="black-btn order-btn"
              onClick={(e) => {

                this.addThisDeliveryAddress()
              }
              }
            >
              Add Address
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.isOrderPlaced ? (
          <Sweetalert
            title="ORDER PLACED!"
            success
            confirmBtnText="Continue Shopping"
            confirmBtnStyle={{
              backgroundColor: "black",
              padding: "10px 15%",
            }}
            onConfirm={() => this.goToHomePage()}
            allowEscape={false}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(styles)(DeliveryAddress);
